import * as React from "react"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import WifiIcon24x24 from "../../atoms/icons/WifiIcon24x24"
import ButtonRow from "../../molecules/card/ButtonRow"
import ToggleButton from "../../atoms/inputs/ToggleButton"
import DropdownEditor from "../../molecules/inputs/DropdownEditor"
import TurnOffWifiModal from "../modals/TurnOffWifiModal"
import { SECTION_OVERVIEW_WIFI } from "../../../utils/pages"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import TextEditor from "../../molecules/inputs/TextEditor"

const WifiCard: React.FunctionComponent = () => {
  const { config } = useEdgeApi()

  // not sure if we want this was from the figma design, also there was no SSID field.
  const wifi = config.useWifiEnabled("Wifi")

  const [turnOffWifiModalOpen, setTurnOffWifiModalOpen] = React.useState(false)

  const handleWifiToggle = () => {
    if (wifi?.value) {
      setTurnOffWifiModalOpen(true)
    } else {
      wifi?.setValue(true)
    }
  }

  const handleConfirmWifiOff = () => {
    wifi?.setValue(false)
    setTurnOffWifiModalOpen(false)
  }

  return (
    <>
      <SettingsOverviewCard title={SECTION_OVERVIEW_WIFI.name} icon={<WifiIcon24x24 />}>
        <ButtonRow label="Wifi on" button={<ToggleButton active={wifi?.value} onToggle={handleWifiToggle} />} />
        <TextEditor label="SSID" editor={config.useWifiSsid("Wifi")} />
        <TextEditor label="Password" editor={config.useWifiPassword("Wifi")} isSecret={true} />
        <DropdownEditor label="Encryption" editor={config.useWifiSecurity("Wifi")} />
      </SettingsOverviewCard>
      <TurnOffWifiModal
        open={turnOffWifiModalOpen}
        onClose={() => setTurnOffWifiModalOpen(false)}
        onConfirm={handleConfirmWifiOff}
      />
    </>
  )
}

export default WifiCard

// Simplified wrapper over protobuf config to simplify getting and settings config values

import * as configProto from "edge-proto/dist/edge/v1/config"
import {
  ConfigValueMetadata,
  NumberConfigValueMetadata,
  EnumConfigValueMetadata,
  BoolConfigValueMetadata,
  StringConfigValueMetadata,
  DeviceMetadata,
} from "./DeviceMetadata"

import {
  EdgeError,
  MetadataNotAvailableError,
  MissingMetadataError,
  ProtoMissingFieldError,
  UnexpectedConfigTypeError,
  UnexpectedConfigValueError,
} from "./Errors"

// In the best of worlds this type would be exported directly by the generated
// protobuf typescript files. But it's not, so we just copy it here.
//
// NB: The corresponding type in protobuf allows undefined here, but we don't
// for extra type safety.
type ProtoValueType =
  | {
      $case: "missingValue"
      missingValue: configProto.MissingConfigValue
    }
  | ({
      int32Value?: number | undefined
    } & {
      $case: "int32Value"
    })
  | ({
      uint32Value?: number | undefined
    } & {
      $case: "uint32Value"
    })
  | ({
      stringValue?: string | undefined
    } & {
      $case: "stringValue"
    })
  | ({
      floatValue?: number | undefined
    } & {
      $case: "floatValue"
    })
  | ({
      boolValue?: boolean | undefined
    } & {
      $case: "boolValue"
    })

// Sum type of all javascript types that are used in the applicatio
// layer for handling of config values.
export type JsConfigValue = string | number | boolean

export type ConfigValueWithExtraData<T> = {
  configValue: configProto.ConfigValue
  extraData: T
}

export type BadConfigValue<T> = {
  errorMessage: string
  extraData: T
}

// Encapsulates an arbitrary type and embeds it with a progress number, which
// is used to indicate progression to the end-user while a new data is being
// applied.
interface Progress<D> {
  progressData: D
  progress: number | undefined
}

export type UserChangedValue<T> =
  | { type: "valid"; value: ConfigValueWithExtraData<T> }
  | { type: "invalid"; value: BadConfigValue<T> }

// Represent the values that has been changed by the end-user. The value is
// stored in order to get a diff of the device's config and the configuration
// that has been applied in the UI.
type UserChangedValues<T> = { [key: string]: UserChangedValue<T> }

export interface ConfigValue {
  toString(): [string, string, StringConfigValueMetadata] | UnexpectedConfigValueError
  toBool(): [boolean, boolean, BoolConfigValueMetadata] | UnexpectedConfigValueError
  toNumber(): [number, number, NumberConfigValueMetadata] | UnexpectedConfigValueError
  toEnum<T>(): [T, T, EnumConfigValueMetadata] | UnexpectedConfigValueError
}

function protoValueToString(value?: ProtoValueType): string {
  const missing = "none"
  if (value === undefined) {
    return missing
  }
  switch (value.$case) {
    case "missingValue": {
      return "MISSING"
    }
    case "boolValue": {
      if (value.boolValue === undefined) {
        return missing
      }
      return value.boolValue ? "true" : "false"
    }
    case "int32Value": {
      return value.int32Value?.toString() ?? missing
    }
    case "floatValue": {
      return value.floatValue?.toString() ?? missing
    }
    case "uint32Value": {
      return value.uint32Value?.toString() ?? missing
    }
    case "stringValue": {
      return value.stringValue?.toString() ?? missing
    }
  }
}

function compareConfigValues(c1: configProto.ConfigValue, c2: configProto.ConfigValue): boolean {
  const compareArray = (a1: Uint8Array, a2: Uint8Array): boolean => {
    if (a1.length !== a2.length) {
      return false
    }
    return a1.every((value, index) => value === a2[index])
  }

  const toArray = (configValue: configProto.ConfigValue): Uint8Array => {
    return configProto.ConfigValue.encode(configValue).finish()
  }

  return compareArray(toArray(c1), toArray(c2))
}

export function configValue(
  configValueMetadata: ConfigValueMetadata,
  protoValue: configProto.ConfigValue,
  protoValueDevice: configProto.ConfigValue,
): ConfigValue {
  const value = protoValue.value
  const deviceValue = protoValueDevice.value

  const error = (msg: string): UnexpectedConfigValueError => {
    return new UnexpectedConfigValueError(
      `For config ${protoValue.id} with value ${protoValueToString(protoValue.value)}: ${msg}`,
    )
  }

  const missingValueError = () => error("Config value is missing in proto definition")

  const unexpectedTypeError = (expectedType: string) =>
    error(`Got unexpected type ${value?.$case}, but expected a ${expectedType}`)

  const unexpectedMetadataType = (expectedType: string) =>
    error(`Got unexpected metadata type ${configValueMetadata.type}, but expected a ${expectedType}`)

  return {
    toString(): [string, string, StringConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (deviceValue.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (configValueMetadata.type !== "string") {
        return unexpectedMetadataType("string")
      }
      return [value.stringValue, deviceValue.stringValue, configValueMetadata]
    },
    toBool(): [boolean, boolean, BoolConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "boolValue") {
        return unexpectedTypeError("bool")
      }
      if (deviceValue.$case !== "boolValue") {
        return unexpectedTypeError("bool")
      }
      if (configValueMetadata.type !== "bool") {
        return unexpectedMetadataType("bool")
      }
      return [value.boolValue, deviceValue.boolValue, configValueMetadata]
    },
    toNumber(): [number, number, NumberConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      switch (value.$case) {
        case "floatValue": {
          if (configValueMetadata.type !== "float") {
            return unexpectedMetadataType("float")
          }
          if (deviceValue.$case !== "floatValue") {
            return unexpectedMetadataType("float")
          }
          return [value.floatValue, deviceValue.floatValue, configValueMetadata]
        }
        case "int32Value": {
          if (configValueMetadata.type !== "int") {
            return unexpectedMetadataType("int")
          }
          if (deviceValue.$case !== "int32Value") {
            return unexpectedMetadataType("int")
          }
          return [value.int32Value, deviceValue.int32Value, configValueMetadata]
        }
        case "uint32Value": {
          if (configValueMetadata.type !== "uint") {
            return unexpectedMetadataType("uint")
          }
          if (deviceValue.$case !== "uint32Value") {
            return unexpectedMetadataType("uint")
          }
          return [value.uint32Value, deviceValue.uint32Value, configValueMetadata]
        }
        default:
          return unexpectedTypeError("int | uint | float")
      }
    },
    toEnum<T>(): [T, T, EnumConfigValueMetadata] | UnexpectedConfigValueError {
      if (value === undefined) {
        return missingValueError()
      }
      if (deviceValue === undefined) {
        return missingValueError()
      }
      if (value.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      if (deviceValue.$case !== "stringValue") {
        return unexpectedTypeError("string")
      }
      switch (configValueMetadata.type) {
        case "enum":
          if (!configValueMetadata.isValid(value.stringValue)) {
            return error(`Expected enum value to be one of ${configValueMetadata.choices}`)
          }
          return [value.stringValue as T, deviceValue.stringValue as T, configValueMetadata]
        default:
          return unexpectedTypeError("enum")
      }
    },
  }
}

export type ReadConfigState = { state: "read" }
export type ReadFailedConfigState = { state: "readFailed" }
export type NormalConfigState = { state: "normal" }
export type DiscardConfigState<T> = { state: "discard"; values: UserChangedValue<T>[] }
export type PushConfigState<T> = {
  state: "push"
  appliedValues: ConfigValueWithExtraData<T>[]
  values: Progress<ConfigValueWithExtraData<T>>[]
}
export type PushFailedConfigState<T> = {
  state: "pushFailed"
  failedValues: ConfigValueWithExtraData<T>[]
  successfulValues: ConfigValueWithExtraData<T>[]
}

export type ConfigState<T> =
  // Initial config state and is used when reading
  // values from the device.
  | ReadConfigState
  // If the state is ReadConfigState and the read fails for
  // some reason.
  | ReadFailedConfigState
  | NormalConfigState
  // Changed values has just been discarded.
  | DiscardConfigState<T>
  // Changes are currently being pushed to the device.
  | PushConfigState<T>
  // If the state is PushConfigState, but config values
  // failed to be applied, this state is entered.
  | PushFailedConfigState<T>

type Namespace<T> = {
  userChangedValues: UserChangedValues<T>
  state: ConfigState<T>
}

export type NamespaceId = string

type Namespaces<T> = { [key: NamespaceId]: Namespace<T> }

interface ValuesContainer<T> {
  deviceValues: { [key: string]: configProto.ConfigValue }
  namespaces: Namespaces<T>
}

/**
 * Data model containing config values.
 *
 * Many functions in this datamodel takes an optional namespace as an argument.
 * A namespace encapsulates the end-user's changed values in isolated "bins".
 * The purpose for this is to provide a simple API that can get/set/push
 * config values independently of other callers that uses the same functions
 * but with different namespaces. The main use of this is when the applications
 * needs UI that only edits and pushes a limited scope of config values. An
 * example of this is in the WebGUI that has a beam switch dialog, which can
 * set and update the satellite beam. But setting and pushing the beam id
 * to the device should not affect the rest of the application, so these
 * functions should be called with a different namespace than the rest
 * of the application, in order to isolate changes.
 */
export interface Config<T> {
  /**
   * Returns a config value given a config ID.
   *
   * @returns the config value. The value
   * is undefined if the config value hasn't been received from the
   * edge API yet or the metadata isn't available yet.
   */
  value(id: string, namespace?: NamespaceId): ConfigValue | UnexpectedConfigValueError | undefined
  valueMetadata(id: string): ConfigValueMetadata | MissingMetadataError | undefined
  /**
   * Updates a config value given an ID and the new value. The function should be called
   * when the end-user changes the value of config values.
   *
   * @param id of the config value
   * @param value the javascript value used to update the config value
   * @param extraData the generic parameter is used to save arbitrary data with
   *        the updated value. The extraData value is returned by other
   *        functions such as the changedValues() function. The extra data
   *        should be used in cases where it makes sense to save UI data
   *        related to the config value. For example, the WebGUI application
   *        sets the generic value as a string and stores the "widget origin"
   *        of the data, so the set of widget that has altered config values
   *        can be displayed in the banner used to push the config values to
   *        the device.
   * @param the namespace that will be affected by the new value.
   */
  setValue(
    id: string,
    value: JsConfigValue,
    extraData: T,
    namespace?: NamespaceId,
  ): Config<T> | MetadataNotAvailableError
  /**
   * Called when the end-user provides an ill formatted value in the UI.
   * For example, if the end-user provides "abc" in a float field. If the
   * end-user enters a valid value later, the setValue function should
   * be called and the value will automatically enter a valid state.
   *
   * @param id of the config value
   * @param errorMessage the error message used for end-user feedback about
   *        the provided
   * @param extraData see setValue for more documentation
   * @param the namespace that will be affected by the new value.
   */
  setInvalidValue(id: string, errorMessage: string, extraData: T, namespaceId?: NamespaceId): Config<T>
  /**
   * All user changed values. This is the values that differs from the UI and the
   * device's config values.
   */
  changedValues(namespace?: NamespaceId): UserChangedValue<T>[]
  /**
   * If the user has changed any values.
   */
  hasChangedValues(namespace?: NamespaceId): boolean
  /**
   * If the user have any invalid values set.
   */
  hasInvalidChangedValues(namespace?: NamespaceId): boolean
  /**
   * Discard all changed values.
   */
  discard(namespace?: NamespaceId): Config<T>
  /**
   * Create a protobuf message that can be sent to the edge server to the
   * /api/ws/config?update endpoint in order to apply all changed config values.
   * Note that beginPush() should be called when this function is called.
   */
  pushChangedConfigProtoMessage(namespace?: NamespaceId): configProto.ClientConfigMessage
  /**
   * Injects metadata so that config values can be parsed.
   */
  setMetadata(metadata: DeviceMetadata): Config<T>
  /**
   * Update config given a config message from the edge server.
   */
  update(metricMessage: configProto.ConfigMessage): Config<T> | ProtoMissingFieldError
  state(namespace?: NamespaceId): ConfigState<T>
  /**
   * Set the config to a pushing state. This function should be called
   * right before the config is updated by sending the update request to
   * the device.
   *
   * The changes will only be pushed for config values in the given namespace.
   */
  beginPush(namespace?: NamespaceId): Config<T>
  /**
   * The the config to a normal state or to a "pushFailed" state.
   */
  endPush(namespace?: NamespaceId): Config<T>

  /**
   * Transititions the state to a "normal" or a "readFailed" state.
   * This function must be called regardless of the outcome after
   * applying the values.
   */
  endRead(): Config<T>
}

interface ParsedDeviceConfigValue {
  state: configProto.ConfigState
  value: configProto.ConfigValue
}

function parseProtoConfigValues(
  configValues: configProto.DeviceConfigValue[],
): ParsedDeviceConfigValue[] | ProtoMissingFieldError {
  return configValues.reduce(
    (obj, value) => {
      if (obj instanceof ProtoMissingFieldError) {
        return obj
      }
      if (value.state === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "state")
      }
      if (value.value === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "value")
      }
      return [...obj, { state: value.state, value: value.value }]
    },
    <ParsedDeviceConfigValue[] | ProtoMissingFieldError>[],
  )
}

function jsValueToProtoValueConfig(
  id: string,
  value: JsConfigValue,
  metadata: ConfigValueMetadata,
): configProto.ConfigValue | UnexpectedConfigTypeError | MissingMetadataError {
  const valueF = (): ProtoValueType | UnexpectedConfigTypeError | MissingMetadataError => {
    const errorType = (expectedType: string, gotType: string): UnexpectedConfigTypeError =>
      new UnexpectedConfigTypeError(`Unexpected type, expected ${expectedType} but got ${gotType} for id ${id}`)

    switch (metadata.type) {
      case "int": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "int32Value",
          int32Value: value,
        }
      }
      case "uint": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "uint32Value",
          uint32Value: value,
        }
      }
      case "string": {
        if (typeof value !== "string") {
          return errorType("string", typeof value)
        }
        return {
          $case: "stringValue",
          stringValue: value,
        }
      }
      case "enum": {
        if (typeof value !== "string") {
          return errorType("string", typeof value)
        }
        return {
          $case: "stringValue",
          stringValue: value,
        }
      }
      case "float": {
        if (typeof value !== "number") {
          return errorType("number", typeof value)
        }
        return {
          $case: "floatValue",
          floatValue: value,
        }
      }
      case "bool": {
        if (typeof value !== "boolean") {
          return errorType("boolean", typeof value)
        }
        return {
          $case: "boolValue",
          boolValue: value,
        }
      }
      case undefined: {
        return new ProtoMissingFieldError("ConfigValueMetadata", "type")
      }
    }
  }

  const protoValue = valueF()

  if (protoValue instanceof EdgeError) {
    return protoValue
  }

  return configProto.ConfigValue.create({
    id,
    value: protoValue,
  })
}

function applyDeviceValues<T>(
  namespaceId: NamespaceId | undefined,
  prevValues: ValuesContainer<T>,
  newConfigValues: ParsedDeviceConfigValue[],
): ValuesContainer<T> | ProtoMissingFieldError {
  return newConfigValues.reduce(
    (newValues, value) => {
      if (newValues instanceof ProtoMissingFieldError) {
        return newValues
      }
      const id = value.value.id
      const state = value.state.state
      if (state === undefined) {
        return new ProtoMissingFieldError("DeviceConfigValue", "state")
      }

      const updateValue = (value: configProto.ConfigValue, progress: number | undefined) => {
        const setState = (state: ConfigState<T>): ConfigState<T> => {
          if (state.state === "push") {
            return {
              ...state,
              values: state.values.map((progressValue) => {
                if (progressValue.progressData.configValue.id === id) {
                  return { ...progressValue, ...{ progress } }
                }
                return progressValue
              }),
            }
          }
          return state
        }
        return updateState(
          {
            ...newValues,
            ...{
              deviceValues: {
                ...newValues.deviceValues,
                ...{ [id]: value },
              },
            },
          },
          namespaceId,
          setState,
        )
      }

      switch (state.$case) {
        case "commited": {
          return updateValue(value.value, undefined)
        }
        case "pending": {
          let progress = undefined
          const msgProgress = state.pending.pendingProgress
          if (msgProgress !== -1) {
            progress = state.pending.pendingProgress
          }
          return updateValue(value.value, progress)
        }
        case "fail": {
          // TODO: Handle error and make it show in UI
          return updateValue(value.value, undefined)
        }
      }
    },
    <ValuesContainer<T> | ProtoMissingFieldError>prevValues,
  )
}

function initNamespace<T>(): Namespace<T> {
  return { userChangedValues: {}, state: { state: "read" } }
}

function getNamespace<T>(valuesContainer: ValuesContainer<T>, namespace?: NamespaceId): Namespace<T> {
  return valuesContainer.namespaces[namespace ?? ""] ?? initNamespace()
}

function updateNamespace<T>(
  prevValues: ValuesContainer<T>,
  namespaceId: NamespaceId | undefined,
  updateFunc: (ns: Namespace<T>) => Namespace<T>,
): ValuesContainer<T> {
  const id = namespaceId ?? ""
  const namespace = getNamespace(prevValues, namespaceId)
  return { ...prevValues, ...{ namespaces: { ...prevValues.namespaces, ...{ [id]: updateFunc(namespace) } } } }
}

function updateState<T>(
  prevValues: ValuesContainer<T>,
  namespaceId: NamespaceId | undefined,
  updateFunc: (prevState: ConfigState<T>) => ConfigState<T>,
): ValuesContainer<T> {
  return updateNamespace(prevValues, namespaceId, (namespace: Namespace<T>) => {
    return { ...namespace, ...{ state: updateFunc(namespace.state) } }
  })
}

function newConfigWithValues<T>(values: ValuesContainer<T>, metadata?: DeviceMetadata): Config<T> {
  const noop = () => newConfigWithValues(values, metadata)

  const valueMetadata = (id: string): ConfigValueMetadata | MissingMetadataError | undefined => {
    if (metadata === undefined) {
      return undefined
    }
    const configValueMetadata = metadata.configValue(id)
    if (configValueMetadata === undefined) {
      return new UnexpectedConfigValueError(`Unexpected config value with id ${id}`)
    }
    if (configValueMetadata instanceof MissingMetadataError) {
      return configValueMetadata
    }
    return configValueMetadata
  }

  const changedValues = (namespaceId?: NamespaceId): UserChangedValue<T>[] => {
    return Object.values(getNamespace(values, namespaceId).userChangedValues)
  }

  const validChangedValues = (namespaceId?: NamespaceId): ConfigValueWithExtraData<T>[] => {
    return changedValues(namespaceId)
      .map((changedValue: UserChangedValue<T>) => (changedValue.type === "valid" ? changedValue.value : null))
      .filter((changedValue) => changedValue !== null)
      .map((changedValue) => changedValue as ConfigValueWithExtraData<T>)
  }

  const applyUserValue = (
    prevValues: ValuesContainer<T>,
    namespaceId: NamespaceId | undefined,
    value: configProto.ConfigValue,
    extraData: T,
  ): ValuesContainer<T> | UnexpectedConfigValueError => {
    const deviceValue = values.deviceValues[value.id]
    if (deviceValue === undefined) {
      return new UnexpectedConfigValueError(
        `Unable to apply a config value ${value.id} since such a value isn't available in the device`,
      )
    }
    return updateNamespace(prevValues, namespaceId, (namespace: Namespace<T>) => {
      if (compareConfigValues(deviceValue, value)) {
        const newUserValues = { ...namespace.userChangedValues }
        delete newUserValues[value.id]
        return { ...namespace, ...{ userChangedValues: newUserValues } }
      }
      return {
        ...namespace,
        ...{
          userChangedValues: {
            ...namespace.userChangedValues,
            ...{ [value.id]: { type: "valid", value: { configValue: value, extraData } } },
          },
        },
      }
    })
  }

  return {
    value(
      id: string,
      namespaceId?: NamespaceId,
    ): ConfigValue | undefined | UnexpectedConfigValueError | ProtoMissingFieldError {
      const deviceValue = values.deviceValues[id]
      if (deviceValue === undefined) {
        return undefined
      }
      const userChangedValue = getNamespace(values, namespaceId)?.userChangedValues[id]
      const protoValue =
        userChangedValue?.type === "valid" && userChangedValue.value.configValue !== undefined
          ? userChangedValue.value.configValue
          : deviceValue
      if (protoValue === undefined) {
        return undefined
      }
      const configValueMetadata = valueMetadata(id)
      if (configValueMetadata === undefined) {
        return undefined
      }
      if (configValueMetadata instanceof EdgeError) {
        return configValueMetadata
      }
      return configValue(configValueMetadata, protoValue, deviceValue)
    },
    setValue(
      id: string,
      value: JsConfigValue,
      extraData: T,
      namespaceId?: NamespaceId,
    ): Config<T> | UnexpectedConfigTypeError | MetadataNotAvailableError | UnexpectedConfigValueError {
      const configValueMetadata = valueMetadata(id)
      if (configValueMetadata === undefined) {
        return new MetadataNotAvailableError()
      }
      if (configValueMetadata instanceof MissingMetadataError) {
        return configValueMetadata
      }
      const protoValue = jsValueToProtoValueConfig(id, value, configValueMetadata)
      if (protoValue instanceof EdgeError) {
        return protoValue
      }
      const newValues = applyUserValue(values, namespaceId, protoValue, extraData)
      if (newValues instanceof UnexpectedConfigValueError) {
        return newValues
      }
      return newConfigWithValues(
        updateState(newValues, namespaceId, (_) => {
          return { state: "normal" }
        }),
        metadata,
      )
    },
    setInvalidValue(id: string, errorMessage: string, extraData: T, namespaceId?: NamespaceId): Config<T> {
      const newValues = updateNamespace(values, namespaceId, (namespace: Namespace<T>) => {
        return {
          ...namespace,
          ...{
            userChangedValues: {
              ...namespace.userChangedValues,
              ...{ [id]: { type: "invalid", value: { errorMessage, extraData } } },
            },
          },
        }
      })
      return newConfigWithValues(newValues, metadata)
    },
    changedValues,
    hasChangedValues(): boolean {
      return changedValues().length !== 0
    },
    hasInvalidChangedValues(): boolean {
      return changedValues().length !== validChangedValues().length
    },
    discard(namespaceId?: NamespaceId): Config<T> {
      const newValues = updateNamespace(values, namespaceId, (namespace: Namespace<T>) => {
        return { ...namespace, ...{ userChangedValues: {}, state: { state: "discard", values: changedValues() } } }
      })
      return newConfigWithValues(newValues, metadata)
    },
    pushChangedConfigProtoMessage(namespaceId?: NamespaceId): configProto.ClientConfigMessage {
      return configProto.ClientConfigMessage.create({
        updateRequest: {
          configSet: {
            configValues: validChangedValues(namespaceId).map((value) => value.configValue),
          },
        },
      })
    },
    valueMetadata,
    setMetadata(metadata: DeviceMetadata): Config<T> {
      return newConfigWithValues(values, metadata)
    },
    update(metricMessage: configProto.ConfigMessage, namespaceId?: NamespaceId): Config<T> | ProtoMissingFieldError {
      const message = metricMessage.message
      if (message === undefined) {
        return new ProtoMissingFieldError("ConfigMessage", "message")
      }
      switch (message.$case) {
        case "updateResponse":
          return noop()
        case "updateStream": {
          const configSet = message.updateStream.configSet
          if (configSet === undefined) {
            return new ProtoMissingFieldError("UpdateStream", "configSet")
          }
          const configValues = parseProtoConfigValues(configSet.configValues)
          if (configValues instanceof ProtoMissingFieldError) {
            return configValues
          }
          const newValues = applyDeviceValues(namespaceId, values, configValues)
          if (newValues instanceof ProtoMissingFieldError) {
            return newValues
          }

          const setState = (state: ConfigState<T>): ConfigState<T> => {
            if (state.state == "push") {
              return {
                ...state,
                appliedValues: [
                  ...state.appliedValues,
                  ...state.values
                    .map((progressValue) => progressValue.progressData)
                    .filter((value) =>
                      configValues
                        .filter((value) => value.state.state?.$case === "commited")
                        .map((value) => value.value.id)
                        .includes(value.configValue.id),
                    ),
                ],
              }
            }
            return state
          }
          return newConfigWithValues(updateState(newValues, namespaceId, setState), metadata)
        }
        case "error":
          // TODO: Handle error
          return noop()
      }
    },
    state(namespaceId?: NamespaceId): ConfigState<T> {
      return getNamespace(values, namespaceId).state
    },
    beginPush(namespaceId?: NamespaceId): Config<T> {
      return newConfigWithValues(
        updateState(values, namespaceId, (_: ConfigState<T>): ConfigState<T> => {
          return {
            state: "push",
            appliedValues: [],
            values: validChangedValues(namespaceId).map((value) => {
              return { progressData: value, progress: undefined }
            }),
          }
        }),
        metadata,
      )
    },
    endPush(namespaceId?: NamespaceId): Config<T> {
      const setNamespace = (namespace: Namespace<T>): Namespace<T> => {
        const state = namespace.state
        if (state.state == "push" && state.values.length != state.appliedValues.length) {
          const failedValues = state.values
            .map((progressValue) => progressValue.progressData)
            .filter(
              (value) =>
                state.appliedValues.find((appliedValue) => appliedValue.configValue.id === value.configValue.id) ===
                undefined,
            )
          return {
            ...namespace,
            ...{
              userChangedValues: failedValues.reduce((obj, value) => {
                return { ...obj, ...{ [value.configValue.id]: { type: "valid", value } } }
              }, {}),
            },
            ...{
              state: {
                state: "pushFailed",
                failedValues: failedValues,
                successfulValues: state.appliedValues,
              },
            },
          }
        }
        return { ...namespace, ...{ userChangedValues: {}, state: { state: "normal" } } }
      }
      return newConfigWithValues(updateNamespace(values, namespaceId, setNamespace), metadata)
    },
    endRead(): Config<T> {
      const setState = (): ConfigState<T> => {
        if (changedValues().length != 0) {
          return { state: "readFailed" }
        }
        return { state: "normal" }
      }

      return newConfigWithValues(updateState(values, "", setState), metadata)
    },
  }
}

export function newConfig<T>(): Config<T> {
  return newConfigWithValues(
    { deviceValues: {}, namespaces: { [""]: { userChangedValues: {}, state: { state: "read" } } } },
    undefined,
  )
}

import * as React from "react"
import { HorizontalSpacer } from "../atoms/spacers"
import GeneralCard from "../organisms/system/GeneralCard"
import SoftwareCard from "../organisms/system/SoftwareCard"
import TemperaturesCard from "../organisms/system/TemperaturesCard"
import ModemCard from "../organisms/system/ModemCard"
import VoltagesCard from "../organisms/system/VoltagesCard"
import SoftwareDownloadBanner from "../organisms/SoftwareDownloadBanner"
import { isAvailableUpdateNewer } from "../../utils/version-helpers"
import PageContainer from "../atoms/PageContainer"
import { useEdgeApi } from "../../edge/EdgeProvider"

interface SystemPageProps {
  headerHeight: number
}

const SystemPage: React.FunctionComponent<SystemPageProps> = ({ headerHeight }) => {
  const { metadata } = useEdgeApi()
  const version = metadata?.softwareVersion
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [latestVersionAvailable, setLatestVersionAvailable] = React.useState<string>(/*"1.0.1"*/)
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const [isCheckingForUpdates, setIsCheckingForUpdates] = React.useState<boolean>()
  const [userDismissedUpdate, setUserDismissedUpdate] = React.useState<boolean>()
  const [softwareUpdateModalOpen, setInstallOsUpdateModalOpen] = React.useState<boolean>()
  const [useLocalUpdate, setUseLocalUpdate] = React.useState<boolean>()

  const onDismiss = () => {
    setUserDismissedUpdate(true)
  }

  const handleSoftwareUpdateClick = () => {
    setUseLocalUpdate(false)
    setInstallOsUpdateModalOpen(true)
  }

  React.useEffect(() => {
    if (isCheckingForUpdates) {
      // TODO: Fetch latest OS version available
      console.log("Fetching latest OS version available")
    }
  }, [isCheckingForUpdates])

  return (
    <>
      {!userDismissedUpdate &&
        isCheckingForUpdates &&
        version &&
        latestVersionAvailable &&
        isAvailableUpdateNewer(latestVersionAvailable, version) && (
          <SoftwareDownloadBanner
            headerHeight={headerHeight}
            version={latestVersionAvailable}
            onDismiss={onDismiss}
            onLearnMore={handleSoftwareUpdateClick}
          />
        )}
      <PageContainer>
        <HorizontalSpacer spacing={4} />
        <GeneralCard />
        <HorizontalSpacer spacing={2} />
        <SoftwareCard
          isCheckingForUpdates={isCheckingForUpdates}
          latestOsVersionAvailable={latestVersionAvailable}
          version={version}
          setIsCheckingForUpdates={setIsCheckingForUpdates}
          softwareUpdateModalOpen={softwareUpdateModalOpen}
          useLocalUpdate={useLocalUpdate}
          onSoftwareUpdateClick={handleSoftwareUpdateClick}
          setUseLocalUpdate={setUseLocalUpdate}
          setInstallOsUpdateModalOpen={setInstallOsUpdateModalOpen}
        />
        <HorizontalSpacer spacing={2} />
        <ModemCard />
        <HorizontalSpacer spacing={2} />
        <TemperaturesCard />
        <HorizontalSpacer spacing={2} />
        <VoltagesCard />
        <HorizontalSpacer spacing={4} />
      </PageContainer>
    </>
  )
}

export default SystemPage

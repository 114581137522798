// Simplified wrapper over protobuf config to simplify getting and settings config values

import * as configProto from "edge-proto/dist/edge/v1/config"
import { ALL_EDITOR_DECIMAL_COUNT } from "../components/constants"
import { Config, ConfigState, ConfigValue, JsConfigValue, NamespaceId, UserChangedValue } from "./Config"
import {
  BoolConfigValueMetadata,
  ConfigValueMetadata,
  EnumConfigValueMetadata,
  NumberConfigValueMetadata,
  Range,
  StringConfigValueMetadata,
} from "./DeviceMetadata"
import { BoolEditor, EnumEditor, StringEditor, NumberEditor, ParseResult } from "./editor"
import { EdgeError } from "./Errors"

export interface ConfigValueNoError {
  toString(): [string, string, StringConfigValueMetadata] | undefined
  toNumber(): [number, number, NumberConfigValueMetadata] | undefined
  toBool(): [boolean, boolean, BoolConfigValueMetadata] | undefined
  toEnum<T>(): [T, T, EnumConfigValueMetadata] | undefined
}

/**
 * The UIConfig is an abstraction and simpler interface in UI related code
 * where an config errors doesn't matter, in contrast to the {@link Config}
 * interface which has similar functions but returns errors instead. The
 * implementation hides any errors and calls a callback instead in case of
 * eventual errors. The callback could for example trigger a logging message
 * or display a dialog to the end-user.
 */
export interface UIConfig<T> {
  value(id: string, namespace?: NamespaceId): ConfigValueNoError | undefined
  setValue(id: string, value: JsConfigValue, extraData: T, namespace?: NamespaceId): void
  setInvalidValue(id: string, errorMessage: string, extraData: T, namespace?: NamespaceId): void
  changedValues(namespace?: NamespaceId): UserChangedValue<T>[]
  hasChangedValues(namespace?: NamespaceId): boolean
  hasInvalidChangedValues(namespace?: NamespaceId): boolean
  pushToDevice(namespace?: NamespaceId): void
  discard(namespace?: NamespaceId): void
  displayValue(value: configProto.ConfigValue): string
  valueMetadata(id: string): ConfigValueMetadata | undefined

  state(namespace?: NamespaceId): ConfigState<T>

  useStringValue(id: string, extraData: T, namespace?: NamespaceId): StringEditor | undefined
  useNumberValue(id: string, extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useBoolValue(id: string, extraData: T, namespace?: NamespaceId): BoolEditor | undefined
  useEnumValue<D extends string>(id: string, extraData: T, namespace?: NamespaceId): EnumEditor<D> | undefined
}

function configValueNoError(value: ConfigValue, onError: (error: EdgeError) => void): ConfigValueNoError {
  const handleError = <T>(value: T | EdgeError | undefined): T | undefined => {
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    return value
  }

  return {
    toString(): [string, string, StringConfigValueMetadata] | undefined {
      return handleError(value.toString())
    },
    toNumber(): [number, number, NumberConfigValueMetadata] | undefined {
      return handleError(value.toNumber())
    },
    toBool(): [boolean, boolean, BoolConfigValueMetadata] | undefined {
      return handleError(value.toBool())
    },
    toEnum<T>(): [T, T, EnumConfigValueMetadata] | undefined {
      return handleError(value.toEnum())
    },
  }
}

export function newUIConfig<T>(
  config: Config<T>,
  setConfig: (newConfig: Config<T>) => void,
  pushToDevice: (namespace?: NamespaceId) => void,
  onError: (error: EdgeError) => void,
): UIConfig<T> {
  const getValue = (id: string, namespace?: NamespaceId): ConfigValueNoError | undefined => {
    const value = config.value(id, namespace)
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    if (value === undefined) {
      return undefined
    }
    return configValueNoError(value, onError)
  }

  const setValue = (id: string, value: JsConfigValue, extraData: T, namespace?: NamespaceId): void => {
    const newConfig = config.setValue(id, value, extraData, namespace)
    if (newConfig instanceof EdgeError) {
      onError(newConfig)
      return
    }
    setConfig(newConfig)
  }

  const setInvalidValue = (id: string, errorMessage: string, extraData: T, namespace?: NamespaceId): void => {
    setConfig(config.setInvalidValue(id, errorMessage, extraData, namespace))
  }

  const valueMetadata = (id: string): ConfigValueMetadata | undefined => {
    const metadata = config.valueMetadata(id)
    if (metadata instanceof EdgeError) {
      onError(metadata)
      return undefined
    }
    return metadata
  }

  const isDiscarded = (namespace?: NamespaceId) => config.state(namespace).state === "discard"

  return {
    value: getValue,
    setValue,
    setInvalidValue,
    changedValues(): UserChangedValue<T>[] {
      return config.changedValues()
    },
    hasChangedValues(namespace?: NamespaceId): boolean {
      return config.hasChangedValues(namespace)
    },
    hasInvalidChangedValues(namespace?: NamespaceId): boolean {
      return config.hasInvalidChangedValues(namespace)
    },
    pushToDevice(namespace?: NamespaceId): void {
      pushToDevice(namespace)
    },
    discard(namespace?: NamespaceId): void {
      setConfig(config.discard(namespace))
    },
    displayValue(configValue: configProto.ConfigValue): string {
      const metadata = valueMetadata(configValue.id)
      if (metadata === undefined) {
        return ""
      }
      const value = configValue.value
      switch (value?.$case) {
        case "missingValue": {
          return "n/a"
        }
        case "boolValue": {
          return value.boolValue ? "On" : "Off"
        }
        case "int32Value": {
          return value.int32Value.toString()
        }
        case "uint32Value": {
          return value.uint32Value.toString()
        }
        case "floatValue": {
          return value.floatValue.toString()
        }
        case "stringValue": {
          switch (metadata.type) {
            case "enum": {
              return metadata.readableEnumValue(value.stringValue)
            }
          }
          return value.stringValue
        }
        case undefined: {
          return ""
        }
      }
    },
    valueMetadata,
    useStringValue(id: string, extraData: T, namespace?: NamespaceId): StringEditor | undefined {
      const stringValue = getValue(id, namespace)?.toString()
      if (stringValue === undefined) {
        return undefined
      }
      const [value, deviceValue, _] = stringValue
      return {
        value,
        discarded: isDiscarded(namespace),
        deviceValue,
        setValue(value: string): void {
          setValue(id, value, extraData, namespace)
        },
        setInvalidValue(errorMessage: string): void {
          setInvalidValue(id, errorMessage, extraData, namespace)
        },
        parseString(_value: string): ParseResult<string> {
          return { result: "success", value: _value }
        },
        display(value: string): string {
          return value
        },
      }
    },
    useNumberValue(id: string, extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      const numberValue = getValue(id, namespace)?.toNumber()
      if (numberValue === undefined) {
        return undefined
      }
      const [value, deviceValue, metadata] = numberValue
      return {
        value,
        discarded: isDiscarded(namespace),
        deviceValue,
        setValue(value: number): void {
          setValue(id, value, extraData, namespace)
        },
        setInvalidValue(errorMessage: string): void {
          setInvalidValue(id, errorMessage, extraData, namespace)
        },
        parseString(_value: string): ParseResult<number> {
          const parseNumber = (
            parseF: (valueString: string) => number,
            validators: ((value: number) => string | undefined)[],
          ): ParseResult<number> => {
            const parsedValue = parseF(_value)
            const success: ParseResult<number> = { result: "success", value: parsedValue }
            return validators.reduce((parseResult: ParseResult<number>, validator) => {
              if (parseResult.result === "fail") {
                return parseResult
              }
              const error = validator(parsedValue)
              return error === undefined ? parseResult : { result: "fail", errorMessage: error }
            }, success)
          }
          const singleRange = (range: Range, value: number) => {
            if (value < range.lower) {
              return `Provided value should not be lower than ${range.lower}`
            }
            if (value > range.upper) {
              return `Provided value should not be higher than ${range.upper}`
            }
            return undefined
          }
          const multipleRanges = (ranges: Range[], value: number) => {
            const rangesString = ranges.map((range: Range) => `[${range.lower},${range.upper}]`).join(", ")
            return ranges.some((range: Range) => singleRange(range, value) === undefined)
              ? undefined
              : `Provided value is not within valid intervals, please provide a value in one of the following intervals: ${rangesString}.`
          }
          const getRangeValidator = () => {
            if (metadata.ranges.length === 1) {
              return (value: number) => singleRange(metadata.ranges[0], value)
            }
            if (metadata.ranges.length > 1) {
              return (value: number) => multipleRanges(metadata.ranges, value)
            }
            return () => undefined
          }
          const rangeValidator = getRangeValidator()
          switch (metadata.type) {
            case "int": {
              return parseNumber(parseInt, [
                (value) => (isNaN(value) ? "Provided value is not a number" : undefined),
                rangeValidator,
              ])
            }
            case "uint": {
              return parseNumber(parseInt, [
                (value) => (isNaN(value) ? "Provided value is not a number" : undefined),
                (value) => (value < 0 ? "Negative values are not allowed" : undefined),
                rangeValidator,
              ])
            }
            case "float": {
              return parseNumber(parseFloat, [
                (value) => (isNaN(value) ? "Provided value is not a decimal number" : undefined),
                rangeValidator,
              ])
            }
          }
        },
        display(_value: number): string {
          switch (metadata.type) {
            case "int": {
              return _value.toString()
            }
            case "uint": {
              return _value.toString()
            }
            case "float": {
              return _value.toFixed(ALL_EDITOR_DECIMAL_COUNT)
            }
          }
        },
      }
    },
    useBoolValue(id: string, extraData: T, namespace?: NamespaceId): BoolEditor | undefined {
      const boolValue = getValue(id, namespace)?.toBool()
      if (boolValue === undefined) {
        return undefined
      }
      const [value, deviceValue, _] = boolValue
      return {
        value,
        discarded: isDiscarded(namespace),
        deviceValue,
        setValue(value: boolean): void {
          setValue(id, value, extraData, namespace)
        },
      }
    },
    useEnumValue<D extends string>(id: string, extraData: T, namespace?: NamespaceId): EnumEditor<D> | undefined {
      const enumValue = getValue(id, namespace)?.toEnum<D>()
      if (enumValue === undefined) {
        return undefined
      }
      const [value, deviceValue, metadata] = enumValue
      return {
        value,
        discarded: isDiscarded(namespace),
        deviceValue,
        setValue(value: D): void {
          setValue(id, value, extraData, namespace)
        },
        display(value: D): string {
          return metadata.readableEnumValue(value)
        },
        choices(): D[] {
          return metadata.choices as D[]
        },
      }
    },
    state(namespace?: NamespaceId): ConfigState<T> {
      return config.state(namespace)
    },
  }
}

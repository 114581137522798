import React, { forwardRef } from "react"
import styled from "styled-components"

type InputBoxProps = React.InputHTMLAttributes<HTMLInputElement> & { id?: string; error?: boolean | false }

const InputBoxStyle = styled.input.attrs<{ error?: boolean }>({ size: 15 })`
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.greyDark};
  display: flex;
  max-width: 270px;
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.error ? props.theme.colors.redDark : props.theme.colors.greyDisabled)};
  outline: none;
  background: ${(props) => props.theme.colors.buttonLight};
  box-shadow: 0px 0px 4px 0px rgba(119, 119, 119, 0.25) inset;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &:disabled {
    color: ${({ theme }) => theme.colors.greyDisabled};
  }
`

export default forwardRef<HTMLInputElement, InputBoxProps>(function InputBox({ error, ...props }, ref) {
  return (
    <div>
      <InputBoxStyle error={error} {...props} ref={ref} />
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: "32px",
          marginBlock: "auto",
          display: error ? "flex" : "none",
          placeItems: "center",
        }}
      ></div>
    </div>
  )
})

import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import BodyTextLarge from "../../atoms/typography/BodyTextLarge"
import SpinnerIcon from "../../atoms/icons/SpinnerIcon"
import { theme } from "../../theme"
import { SoftwareUpdateState } from "../../../edge/SoftwareUpdate"

interface SoftwareUpdateModalProps {
  softwareUpdateState: SoftwareUpdateState
}

const stateToText = (softwareUpdateState: SoftwareUpdateState): string => {
  switch (softwareUpdateState.progress()) {
    case "begin":
      return "Initializing update.."
    case "reboot_device":
      return "Rebooting device.."
    case "download_software":
      return "Downloading software bundle.."
    case "complete_download_software":
      return "Download completed"
    case "upload_file":
      return "Uploading file.."
    case "unknown":
      return "Applying update.."
  }
}

const SoftwareUpdateProgressModal: React.FunctionComponent<SoftwareUpdateModalProps> = ({ softwareUpdateState }) => {
  const onClose = () => {
    // We do not allow to close the modal during the installation
  }

  const titleSuffix = softwareUpdateState.newVersion ? ` (${softwareUpdateState.newVersion})` : ""

  return (
    <AnimatePresence mode="wait">
      <Modal
        title={`Installing software update${titleSuffix}`}
        leftIcon={<SpinnerIcon />}
        onClose={onClose}
        centerTitle={true}
      >
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <BodyTextLarge color={theme.colors.greyDarker} textAlign="center">
              {stateToText(softwareUpdateState)}
            </BodyTextLarge>
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <BodyTextLarge textAlign="center">
              This may take a few minutes after which the terminal will restart. Make sure the terminal is connected to
              a power source for the duration of the installation.
            </BodyTextLarge>
          </Col>
        </Row>
      </Modal>
    </AnimatePresence>
  )
}

export default SoftwareUpdateProgressModal

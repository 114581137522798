import React, { createContext, useState, useContext, useEffect, useRef } from "react"
import { Ws } from "../websocket/websocket"
import * as protoMetrics from "edge-proto/dist/edge/v1/metrics"
import * as protoConfig from "edge-proto/dist/edge/v1/config"
import * as protoDeviceMetadata from "edge-proto/dist/edge/v1/device_metadata"
import * as protoSoftwareUpdate from "edge-proto/dist/edge/v1/software_update"
import * as protoFileUpload from "edge-proto/dist/edge/v1/file_upload"
import { Metrics, newMetrics } from "./Metrics"
import { MetricValueError, ProtoMissingFieldError, SoftwareUpdateError } from "./Errors"
import { DeviceMetadata, parseMetadata } from "./DeviceMetadata"
import {
  EDGE_WS_METRICS,
  EDGE_WS_EVENTS,
  EDGE_HTTP_METADATA,
  EDGE_WS_CONFIG,
  EDGE_WS_CONFIG_UPDATE,
  EDGE_HTTP_FILE_UPLOAD,
  EDGE_WS_SOFTWARE_UPDATE,
} from "./endpoints"
import { useLogin } from "../auth/LoginProvider"
import { clearUser } from "../redux/slices/userSlice"
import { useDispatch } from "react-redux"
import { StatusCodes } from "http-status-codes"
import { Config, NamespaceId, newConfig } from "./Config"
import { UIConfig, newUIConfig } from "./UIConfig"
import {
  DeviceConfigMotion,
  DeviceMetricsMotion,
  newDeviceConfigMotion,
  newDeviceMetricsMotion,
} from "./device_gen/motion"
import { clientSoftwareUpdateMessage, newSoftwareUpdateState, SoftwareUpdateState } from "./SoftwareUpdate"
import { parseFileUploadMessage } from "./FileUpload"

const SLEEP_POST_SOFTWARE_UPDATE = 3000

// In the WebGUI application, we store the widget name containing the
// the config value for each changed value. This way we can display
// the widget origins of the changed config values in the banner used
// to apply changed values. This is hence the type parameter used in
// Config, UIConfig and DeviceConfigX. See the Config interface
// documentation for more information.
export type WebguiConfigExtraData = string

// We currently only have a single device that exposes
// an edge API. In the future we might have more, so we
// expose this behind a sum type.
//
// export type DeviceConfig = DeviceConfigMotion<string> | FutureDevice<string>
export type DeviceConfig = DeviceConfigMotion<WebguiConfigExtraData>
// Same for Metrics
export type DeviceMetrics = DeviceMetricsMotion

interface EdgeProviderType {
  metadata?: DeviceMetadata
  metrics: DeviceMetrics
  uiConfig: UIConfig<WebguiConfigExtraData>
  config: DeviceConfig
  events: { [key: string]: number }
  connected: boolean
  updateSoftware: (file: File) => void
  softwareUpdateState: SoftwareUpdateState | undefined
}

const EdgeContext = createContext<EdgeProviderType | undefined>(undefined)

const useDeviceMetadata = (
  isLoggedIn: boolean,
  onDeviceMetadata: (deviceMetadata: DeviceMetadata) => void,
): DeviceMetadata | undefined => {
  const dispatch = useDispatch()

  const [metadata, setMetadata] = useState<DeviceMetadata | undefined>(undefined)

  useEffect(() => {
    async function fetchMetadata() {
      if (!isLoggedIn) {
        setMetadata(undefined)
        return
      }
      // TODO: Handle error
      const response = await fetch(EDGE_HTTP_METADATA, { credentials: "include" }).catch((_err) => {
        console.log(_err)
      })
      // TODO: Handle error
      if (response === undefined) {
        return
      }
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          dispatch(clearUser("api_unauthorized"))
        }
        return
      }
      const bodyBuffer = await response.arrayBuffer()
      const deviceMetadataMessage = protoDeviceMetadata.DeviceMetadataMessage.decode(new Uint8Array(bodyBuffer))
      const deviceMetadata = parseMetadata(deviceMetadataMessage)
      if (deviceMetadata instanceof ProtoMissingFieldError) {
        // TODO: Handle error
        console.log(`Missing field in DeviceMetadataMessage: ${deviceMetadata}`)
        return
      }
      onDeviceMetadata(deviceMetadata)
      setMetadata(deviceMetadata)
    }
    fetchMetadata()
    return
  }, [isLoggedIn])
  return metadata
}

const useMetrics = (isLoggedIn: boolean): [Metrics, boolean, (deviceMetdata: DeviceMetadata) => void] => {
  // TODO: Handle error
  const [metrics, setMetrics] = useState(
    newMetrics((error) => {
      console.log(`Unexpected error on metrics: ${error}`)
    }),
  )
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  const warnedMessages = useRef<Set<string>>(new Set())
  // We don't want to spam alerts on each incomming faulty metric message,
  // so we use this guard here.
  const warnMetric = (message: string) => {
    if (warnedMessages.current.has(message)) {
      return
    }
    warnedMessages.current.add(message)
    alert(`Unexpected issue with incomming metric message: ${message}`)
  }

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      setMetrics((prevMetrics) => {
        let metricMessage
        try {
          // Decode issues aren't really a thing for the typescript protobuf generation implementation.
          // For example a bogus protobuf message results in a message with undefined fields. However,
          // the only exception to this seems to be when number types get values that are larger than
          // Number.MAX_SAFE_INTEGER. We don't really use int64, but the timestamp uses an int64 internally.
          // Hence, if a timestamp with a really large value is received, which can happen if edge is buggy,
          // an error is thrown.
          metricMessage = protoMetrics.MetricMessage.decode(new Uint8Array(ev.data))
        } catch (err) {
          if (!(err instanceof Error)) {
            warnMetric(`Unexpected error type in metrics: ${err}`)
          } else {
            warnMetric(err.message)
          }
          return prevMetrics
        }
        const newMetrics = prevMetrics.update(metricMessage)
        if (newMetrics instanceof ProtoMissingFieldError) {
          // TODO: Show error here
          console.log(`Protobuf metric message is missing a field: ${newMetrics.message}`)
          return prevMetrics
        }
        if (newMetrics instanceof MetricValueError) {
          // TODO: Show error here
          console.log(newMetrics.message)
          return prevMetrics
        }
        return newMetrics
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (_: Ws): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_METRICS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  return [
    metrics,
    connected,
    (deviceMetadata) => {
      setMetrics((prevMetrics) => prevMetrics.setMetadata(deviceMetadata))
    },
  ]
}

const useConfig = (
  isLoggedIn: boolean,
): [
  Config<WebguiConfigExtraData>,
  (newConfig: Config<WebguiConfigExtraData>) => void,
  () => void,
  (deviceMetdata: DeviceMetadata) => void,
] => {
  // TODO: Handle error
  const [config, setConfig] = useState(newConfig<WebguiConfigExtraData>())

  const wsRef = useRef<Ws | undefined>(undefined)

  const handleServerMessage = (ev: MessageEvent<ArrayBuffer>): void => {
    const updateConfig = (prevConfig: Config<WebguiConfigExtraData>): Config<WebguiConfigExtraData> => {
      // TODO: Handle decode issues
      const configMessage = protoConfig.ConfigMessage.decode(new Uint8Array(ev.data))
      const newConfig = prevConfig.update(configMessage)
      if (newConfig instanceof ProtoMissingFieldError) {
        // TODO: Show error here
        console.log(`Protobuf config message is missing a field: ${newConfig.message}`)
        return prevConfig
      }
      return newConfig
    }
    setConfig(updateConfig)
  }

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }

    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      handleServerMessage(ev)
    }
    const onopen = (): void => {
      return
    }
    const onclose = (): void => {
      setConfig((config) => config.endRead())
      return
    }
    const onerror = (_: Ws): void => {
      return
    }

    wsRef.current = new Ws(EDGE_WS_CONFIG, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])

  const pushToDevice = (namespace?: NamespaceId) => {
    const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>): void => {
      handleServerMessage(ev)
    }
    const onopen = (ws: Ws): void => {
      const protoMessage = config.pushChangedConfigProtoMessage(namespace)
      setConfig(config.beginPush(namespace))
      ws.send(protoConfig.ClientConfigMessage.encode(protoMessage).finish())
    }
    const onclose = (): void => {
      setConfig((config) => config.endPush(namespace))
      return
    }
    const onerror = (_: Ws): void => {
      // TODO: Handle error
      console.log(`Unexpected websocket error when trying to write config to device server`)
    }

    new Ws(EDGE_WS_CONFIG_UPDATE, onmessage, onopen, onclose, onerror)
    return
  }

  return [
    config,
    setConfig,
    pushToDevice,
    (deviceMetadata) => {
      setConfig((prevMetrics) => prevMetrics.setMetadata(deviceMetadata))
    },
  ]
}

const useEvents = (isLoggedIn: boolean): [{ [key: string]: number }, boolean] => {
  const [events, setEvents] = useState({ count: 0 })
  const [connected, setConnected] = useState(false)

  const wsRef = useRef<Ws | undefined>(undefined)

  useEffect(() => {
    const ws = wsRef.current
    if (ws !== undefined) {
      ws.close()
      wsRef.current = undefined
    }
    if (!isLoggedIn) {
      return
    }
    const onmessage = (): void => {
      setEvents((prevEvents) => {
        // TODO: Here we should parse protobuf message and
        // put in this object once we implement protobuf
        // parsing.
        return { count: prevEvents.count + 1 }
      })
    }
    const onopen = (): void => {
      setConnected(true)
    }
    const onclose = (ws: Ws): void => {
      setConnected(false)
      ws.reconnect()
    }
    const onerror = (): void => {
      setConnected(false)
    }

    wsRef.current = new Ws(EDGE_WS_EVENTS, onmessage, onopen, onclose, onerror)
  }, [isLoggedIn])
  return [events, connected]
}

const useSoftwareUpdate = (): [(file: File) => void, SoftwareUpdateState | undefined] => {
  const dispatch = useDispatch()
  const [updateFile, setUpdateFile] = useState<File | undefined>(undefined)
  const [softwareUpdateState, setSoftwareUpdateState] = useState<SoftwareUpdateState | undefined>(undefined)

  useEffect(() => {
    async function updateSoftware() {
      if (updateFile === undefined) {
        return
      }
      setSoftwareUpdateState(newSoftwareUpdateState("upload_file"))
      const onSoftwareUpdateError = (error: string) => {
        setSoftwareUpdateState(undefined)
        // TODO: Implement proper error dialog
        alert(`Unexpected issue occurred when applying the software update: ${error}`)
      }
      const formData = new FormData()
      formData.append("software_update_bundle", updateFile)
      const response = await fetch(EDGE_HTTP_FILE_UPLOAD, {
        credentials: "include",
        method: "POST",
        body: formData,
      }).catch((error) => {
        onSoftwareUpdateError(`Unable to post request to file_upload endpoint: ${error.message}`)
        return
      })

      if (response === undefined) {
        return
      }
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          dispatch(clearUser("api_unauthorized"))
        }
        onSoftwareUpdateError(`Unexpected response from file_upload endpoint, status code: ${response.status}`)
        return
      }

      const bodyBuffer = await response.arrayBuffer()
      const fileUploadResultMsg = protoFileUpload.FileUploadResultMessage.decode(new Uint8Array(bodyBuffer))
      const fileReferences = parseFileUploadMessage(fileUploadResultMsg)
      if (fileReferences instanceof ProtoMissingFieldError) {
        onSoftwareUpdateError(`Failed to parse file reference from file_upload endpoint: ${fileReferences.message}`)
        return
      }
      if (fileReferences.length !== 1) {
        onSoftwareUpdateError(
          `Unexpected number of file references from file_upload endpoint, expected exactly 1 but got ${fileReferences.length}`,
        )
        return
      }
      const fileReference = fileReferences[0]
      const softwareUpdateMsg = clientSoftwareUpdateMessage(fileReference)
      const onmessage = (_ws: Ws, ev: MessageEvent<ArrayBuffer>) => {
        const updateMsg = protoSoftwareUpdate.SoftwareUpdateMessage.decode(new Uint8Array(ev.data))
        setSoftwareUpdateState((state) => {
          if (state === undefined) {
            return state
          }
          const newState = state.update(updateMsg)
          if (newState instanceof SoftwareUpdateError) {
            onSoftwareUpdateError(newState.message)
            return state
          }
          if (newState instanceof ProtoMissingFieldError) {
            onSoftwareUpdateError(`Failed to parse file reference from file_upload endpoint: ${newState.message}`)
            return state
          }
          return newState
        })
      }
      const onopen = (ws: Ws) => {
        ws.send(protoSoftwareUpdate.ClientSoftwareUpdateMessage.encode(softwareUpdateMsg).finish())
        return
      }
      const onclose = () => {
        setSoftwareUpdateState((state) => {
          if (state === undefined) {
            return undefined
          }
          // TODO: We should really get the new version from state here, and
          // wait for the metadata to to have the new version number before
          // doing a reload here in order to make sure the upgrade was successful.
          // But the edge api currently don't expose the version correctly,
          // so we simply wait and then reload the page.
          new Promise((resolve) => setTimeout(resolve, SLEEP_POST_SOFTWARE_UPDATE)).then(() => window.location.reload())
          return state
        })
      }
      const onerror = () => {
        onSoftwareUpdateError(`Websocket unexpectedly closed with an error`)
        return
      }
      new Ws(EDGE_WS_SOFTWARE_UPDATE, onmessage, onopen, onclose, onerror)
    }
    updateSoftware()
  }, [updateFile])

  const updateSoftware = (file: File) => {
    setUpdateFile(file)
  }

  return [updateSoftware, softwareUpdateState]
}

export const EdgeProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useLogin()
  const [metrics, metricsConnected, setDeviceMetadataMetrics] = useMetrics(isLoggedIn)
  const [config, setConfig, pushToDevice, setDeviceMetadataConfig] = useConfig(isLoggedIn)
  const uiConfig = newUIConfig(config, setConfig, pushToDevice, (error) => {
    // TODO: Add error handling
    console.log(`Unexpected config error: ${error}`)
  })
  // Events are currently not implemented. This can be seen in the developer
  // console.
  const [events, _eventsConnected] = useEvents(isLoggedIn)
  const metadata = useDeviceMetadata(isLoggedIn, (metadata) => {
    setDeviceMetadataMetrics(metadata)
    setDeviceMetadataConfig(metadata)
  })
  const [updateSoftware, softwareUpdateState] = useSoftwareUpdate()
  // TODO: metricsConnected && eventsConnected
  const connected = metricsConnected

  return (
    <EdgeContext.Provider
      value={{
        metrics: newDeviceMetricsMotion(metrics),
        uiConfig,
        config: newDeviceConfigMotion(uiConfig),
        events,
        metadata: metadata,
        connected,
        updateSoftware,
        softwareUpdateState,
      }}
    >
      {children}
    </EdgeContext.Provider>
  )
}

export const useEdgeApi = () => {
  const context = useContext(EdgeContext)
  if (!context) {
    throw new Error("useEdgeApi must be used within a EdgeProvider")
  }
  return context
}

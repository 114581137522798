import * as React from "react"
import { EnumEditor } from "../../../edge/editor"
import ButtonRow from "../card/ButtonRow"
import MultiStateButton from "../../atoms/buttons/MultiStateButton"

interface Props<T> {
  label: string
  editor?: EnumEditor<T>
  placeholders?: T[]
}

const ButtonRowEditor = <T extends string>({ editor, label, placeholders }: Props<T>) => {
  const set = (value: string) => editor?.setValue(value as T)

  const display = (value: string) => editor?.display(value as T) ?? value ?? ""

  return (
    <ButtonRow
      label={label}
      button={
        <MultiStateButton
          id={label}
          onClick={set}
          options={editor?.choices() ?? placeholders ?? []}
          value={editor?.value}
          display={display}
        />
      }
    />
  )
}

export default ButtonRowEditor

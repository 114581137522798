import * as React from "react"
import BodyText from "../typography/BodyText"
import { Align, Col, Row } from "react-grid-system"
import SettingsField from "./SettingsField"
import { theme } from "../../theme"
import { styled } from "styled-components"
import { HorizontalSpacer } from "../spacers"

export interface GenericCardRowWithLabelProps {
  label: string
  children: React.ReactNode
  labelIcon?: React.ReactNode
  labelIconLeft?: React.ReactNode
  compact?: boolean
  alignLabelContent?: Align
  errorMessage?: string
}

const FieldTooltipContainer = styled.div`
  top: 0.6rem;
  padding: 0.75rem;
  margin: 0 !important;
  border-radius: 12px;
  width: 60%;
`

const SettingsFieldWithLabel: React.FunctionComponent<GenericCardRowWithLabelProps> = ({
  label,
  children,
  labelIcon,
  labelIconLeft,
  compact,
  alignLabelContent,
  errorMessage,
}) => {
  return (
    <SettingsField compact={compact}>
      <Row justify="between" align={alignLabelContent ?? "center"} style={{ rowGap: theme.spacings.medium }}>
        <Col xs="content">
          <Row gutterWidth={8} align="center">
            <Col>{labelIconLeft}</Col>
            <BodyText>{label}</BodyText>
            <Col>{labelIcon}</Col>
          </Row>
        </Col>
        <Col xs="content">{children}</Col>
      </Row>
      {errorMessage && (
        <>
          <HorizontalSpacer spacing={0.5} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <FieldTooltipContainer>
              <BodyText color={theme.colors.redDark}>{errorMessage}</BodyText>
            </FieldTooltipContainer>
          </div>
        </>
      )}
    </SettingsField>
  )
}

export default SettingsFieldWithLabel

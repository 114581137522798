import * as React from "react"
import { EditorStringInput } from "../../../edge/editor"
import EditableTextField from "./EditableTextField"
import { useState } from "react"
import HiddenIcon from "../../atoms/icons/HiddenIcon"
import Tooltip, { TooltipContainer, TooltipItem, TooltipPosition } from "../Tooltip"
import QuestionMarkIcon from "../../atoms/icons/QuestionMarkIcon"
import BodyText from "../../atoms/typography/BodyText"
import { theme } from "../../theme"

interface Props<T> {
  label: string
  editor?: EditorStringInput<T>
  isSecret?: boolean
  tooltip?: string
  valueMapper?: (value: T) => T | undefined
}

const tooltipPosition: TooltipPosition = {
  transform: "translateX(-52%)",
  left: "80%",
  paddingTop: "2.25rem",
}

const TextEditor = <T,>({ editor, label, isSecret, valueMapper, tooltip }: Props<T>) => {
  const [editorValue, setEditorValue] = useState<string | undefined>(undefined)

  const mapper = (value?: T) => (valueMapper && value !== undefined ? valueMapper(value) : undefined)

  const value = editorValue ?? editor?.display(mapper(editor.value) ?? editor.value)
  const parseResult = editorValue !== undefined ? editor?.parseString(editorValue) : undefined

  // Makes discard function work
  if (editorValue !== undefined && editor?.discarded) {
    setEditorValue(undefined)
  }

  const onChangeIsEditing = (isEditing: boolean) => {
    if (
      !isEditing &&
      editorValue !== undefined &&
      parseResult?.result == "success" &&
      mapper(editor?.value) !== undefined
    ) {
      setEditorValue(undefined)
    }
  }

  const setValue = (valueString: string) => {
    setEditorValue(valueString)
    if (editor === undefined) {
      return
    }
    const parseResult = editor.parseString(valueString)
    if (parseResult.result === "fail") {
      editor.setInvalidValue(parseResult.errorMessage)
      return
    }
    editor.setValue(parseResult.value)
  }

  const error = parseResult?.result === "fail" ? `Invalid input: ${parseResult.errorMessage}.` : undefined
  const fieldError = error !== undefined ? "Invalid input" : undefined

  const secretProps = isSecret ? { icon: <HiddenIcon />, isSecret: true } : {}

  return (
    <EditableTextField
      {...secretProps}
      errorMessage={error}
      label={label}
      value={value}
      onChangeIsEditing={onChangeIsEditing}
      onChange={setValue}
      error={fieldError}
      labelIcon={
        tooltip && (
          <TooltipContainer>
            <QuestionMarkIcon />
            <Tooltip
              backgroundColor={theme.colors.greyDark}
              borderRadius="12px"
              tooltipPosition={tooltipPosition}
              unClickable={true}
              toolTipPadding="1rem"
            >
              <TooltipItem maxWidth="250px" noHover={true}>
                <BodyText color="white">
                  Note: This password is used by the terminal to access the modem. Changing this password does not
                  change the password in the modem.
                </BodyText>
              </TooltipItem>
            </Tooltip>
          </TooltipContainer>
        )
      }
    />
  )
}

export default TextEditor

export const SECTION_SYSTEM_GENERAL = {
  name: "General",
  path: "#general",
}
export const SECTION_SYSTEM_SOFTWARE = {
  name: "Software",
  path: "#software",
}
export const SECTION_SYSTEM_MODEM = {
  name: "Modem",
  path: "#modem",
}
export const SECTION_SYSTEM_TEMPERATURES = {
  name: "Temperatures",
  path: "#temperatures",
}
export const SECTION_SYSTEM_VOLTAGES = {
  name: "Voltages",
  path: "#voltages",
}

export const SECTION_OVERVIEW_SATELLITE_SIGNAL = {
  name: "Satellite signal",
  path: "#satellite-signal",
}

export const SECTION_OVERVIEW_SYSTEM_MESSAGES = {
  name: "System messages",
  path: "#system-messages",
}

export const SECTION_OVERVIEW_POSITION = {
  name: "Position",
  path: "#position",
}

export const SECTION_OVERVIEW_WIFI = {
  name: "Wifi",
  path: "#wifi",
}

export const SECTION_OVERVIEW_TRANSMISSION = {
  name: "Transmission",
  path: "#transmission",
}

export const pages = {
  overview: {
    name: "Overview",
    path: "/",
    sections: [
      SECTION_OVERVIEW_SATELLITE_SIGNAL,
      SECTION_OVERVIEW_SYSTEM_MESSAGES,
      SECTION_OVERVIEW_POSITION,
      SECTION_OVERVIEW_WIFI,
      SECTION_OVERVIEW_TRANSMISSION,
    ],
  },
  system: {
    name: "System",
    path: "/system",
    sections: [
      SECTION_SYSTEM_GENERAL,
      SECTION_SYSTEM_SOFTWARE,
      SECTION_SYSTEM_MODEM,
      SECTION_SYSTEM_TEMPERATURES,
      SECTION_SYSTEM_VOLTAGES,
    ],
  },
  support: {
    name: "Support",
    path: "/support",
    sections: [
      {
        name: "Contact",
        path: "#contact",
      },
      {
        name: "Guides and manuals",
        path: "#guides-and-manuals",
      },
      // TODO unhide after mvp
      // {
      //   name: "Error code index",
      //   path: "#error-codes",
      // },
    ],
  },
} as const

import * as React from "react"
import styled from "styled-components"
import H3 from "../../atoms/typography/H3"
import { Col, Row } from "react-grid-system"
import { HorizontalSpacer, Spacer } from "../../atoms/spacers"
import BodyText from "../../atoms/typography/BodyText"
import { AntennaOrientation } from "../../../types"
import { MediaQuery } from "../../../utils/design-helpers"
import QuestionMarkIcon from "../../atoms/icons/QuestionMarkIcon"
import Tooltip, { TooltipContainer, TooltipItem, TooltipPosition } from "../Tooltip"
import { theme } from "../../theme"
import { DeepPartial } from "edge-proto/dist/edge/v1/metrics"
import { orientationToString } from "../../../utils/formatters"

interface OrientationFieldProps {
  title: string
  orientation?: DeepPartial<AntennaOrientation>
}

const OrientationFieldContainer = styled.div`
  display: flex;
  min-width: 285px;
  padding: 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-top: 3px solid #b8c7d6;
  border-bottom: 3px solid #b8c7d6;
  background: #fafafa;

  ${MediaQuery.SM} {
    min-width: 0;
    padding: 16px 0px;
    margin: 0px -15px;
  }
`

const OrientationValuesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const polarizationTooltipPosition: TooltipPosition = {
  transform: "translateX(-40%)",
  left: "80%",
  paddingTop: "1.8rem",
}

const OrientationField: React.FunctionComponent<OrientationFieldProps> = ({ title, orientation }) => {
  return (
    <OrientationFieldContainer>
      <H3 style={{ alignSelf: "center" }} textAlign="center">
        {title}
      </H3>
      <HorizontalSpacer spacing={1} />
      <OrientationValuesRow>
        <Col>
          <BodyText textAlign="center">Polarization</BodyText>
          <HorizontalSpacer spacing={1} />
          <Row align="center" justify="center">
            <BodyText textAlign="center" disabled={orientation?.polarization?.value == undefined}>
              {orientationToString(orientation?.polarization?.value) ?? "--"} |{" "}
              {orientation?.polarization?.orientation ?? "-"}
            </BodyText>
            <Spacer spacing={0.5} />
            <TooltipContainer>
              <QuestionMarkIcon />
              <Tooltip
                backgroundColor={theme.colors.greyDark}
                borderRadius="12px"
                tooltipPosition={polarizationTooltipPosition}
                unClickable={true}
                toolTipPadding="1rem"
              >
                <TooltipItem maxWidth="225px">
                  <BodyText color="white">
                    This is the receiving value. The transmitting value is identical but shifted 90˚. I.e. from vertical
                    (V) to horizontal (H), or vice versa.
                  </BodyText>
                </TooltipItem>
              </Tooltip>
            </TooltipContainer>
          </Row>
        </Col>
        <Col>
          <BodyText textAlign="center">Azimuth</BodyText>
          <HorizontalSpacer spacing={1} />
          <BodyText textAlign="center" disabled={orientation?.azimuth == undefined}>
            {orientationToString(orientation?.azimuth)}
          </BodyText>
        </Col>
        <Col>
          <BodyText textAlign="center">Elevation</BodyText>
          <HorizontalSpacer spacing={1} />
          <BodyText textAlign="center" disabled={orientation?.elevation == undefined}>
            {orientationToString(orientation?.elevation)}
          </BodyText>
        </Col>
      </OrientationValuesRow>
    </OrientationFieldContainer>
  )
}

export default OrientationField

// A path error encapsulates errors in nested proto messages.
// The path should be delimted by dots (.). Example:
// first_id.second_id. If protobuf message contains a list,
// the element containing an error should have a path segment
// equal to [idx], for example: first_id.[3].second_id.

export type PathError = {
  type: "error"
  message: string
  path: string
}

export type PathSuccess<T> = {
  type: "success"
  value: T
}

export type PathResult<T> = PathError | PathSuccess<T>

export function pathErrorInit(message: string, path: string): PathError {
  return {
    type: "error",
    message,
    path,
  }
}

export function pathErrorAddPrefix(error: PathError, prefix: string): PathError {
  return { ...error, ...{ path: `${prefix}.${error.path}` } }
}

export function pathSuccessInit<T>(value: T): PathSuccess<T> {
  return {
    type: "success",
    value,
  }
}

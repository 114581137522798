import * as React from "react"
import ModemIcon from "../../atoms/icons/ModemIcon"
import SettingsOverviewCard from "../../molecules/SettingsOverviewCard"
import ReadOnlyTextField from "../../molecules/card/ReadOnlyTextField"
import ButtonRow from "../../molecules/card/ButtonRow"
import ExternalLinkIcon from "../../atoms/icons/ExternalLinkIcon"
import { SECTION_SYSTEM_MODEM } from "../../../utils/pages"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import TextEditor from "../../molecules/inputs/TextEditor"
import { Link } from "react-router-dom"
import { MODEM_PAGE } from "../../../edge/endpoints"

const ModemCard: React.FunctionComponent = () => {
  const { config, metrics } = useEdgeApi()

  const board = metrics.useModemInfoBoard()
  const platform = metrics.useModemInfoPlatform()
  const software = metrics.useModemInfoSoftware()
  const model =
    board !== undefined && platform !== undefined && software !== undefined
      ? `${board} ${platform} ${software}`
      : undefined

  return (
    <SettingsOverviewCard title={SECTION_SYSTEM_MODEM.name} icon={<ModemIcon />}>
      <ReadOnlyTextField label="Model" value={model} placeholder="------" />
      <ReadOnlyTextField label="Serial number" value={metrics.useModemInfoSerial()?.toString()} placeholder="------" />
      <TextEditor
        label="Password"
        tooltip="Note: This password is used by the terminal to access the modem. Changing this password does not change the password in the modem."
        isSecret={true}
        editor={config.useModemUserPassword("Modem")}
      />
      {/* Note that the link /modem doesn't exist in the webgui. A reverse
          should proxy this to an external service */}
      <ButtonRow
        label="Modem configuration (external)"
        button={
          <Link to={MODEM_PAGE} reloadDocument={true} style={{ textDecoration: "none" }}>
            <PrimaryButton size="small" variant="light" title="Go to modem page" iconLeft={<ExternalLinkIcon />} />{" "}
          </Link>
        }
      />
    </SettingsOverviewCard>
  )
}

export default ModemCard

import { FREQUENCY_DECIMAL_COUNT, ORIENTATION_ANGLE_FLOAT_DECIMAL_COUNT } from "../components/constants"

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const dashCase = (string: string) => {
  return string.toLowerCase().replace(" ", "-")
}

export const formatOrbitalSlot = (ddLongitude?: number): string | undefined =>
  ddLongitude !== undefined ? `${Math.abs(ddLongitude).toFixed(0)}°${ddLongitude >= 0 ? "E" : "W"}` : undefined

export type FreqUnit = "MHz" | "GHz"

export const formatFrequency = (freq: string, freqUnit: FreqUnit, rxOrTx?: "rx" | "tx") => {
  const prefix = rxOrTx === undefined ? "" : rxOrTx === "rx" ? "Rx: " : "Tx: "
  return `${prefix}${freq} ${freqUnit}`
}

export const freqToString = (freq: number | undefined, freqUnit: FreqUnit, rx?: "rx" | "tx"): string | undefined => {
  const freqConverted = freq !== undefined ? (freqUnit === "MHz" ? freq : freq / 1000.0) : undefined
  return freqConverted
    ? formatFrequency(freqConverted.toFixed(FREQUENCY_DECIMAL_COUNT).toString(), freqUnit, rx)
    : undefined
}
export const orientationToString = (orientation: number | undefined) => {
  const valueStr = orientation?.toFixed(ORIENTATION_ANGLE_FLOAT_DECIMAL_COUNT)
  return valueStr !== undefined ? `${valueStr}°` : undefined
}

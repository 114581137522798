import * as React from "react"
import InputBox from "./InputBox"
import EditableField from "./EditableSettingsField"

export interface EditableTextFieldProps {
  onChange: (value: string) => void
  value?: string
  label: string
  icon?: React.ReactNode
  isSecret?: boolean
  labelIcon?: React.ReactNode
  isLast?: boolean
  disabled?: boolean
  error?: string
  errorMessage?: string
  onChangeIsEditing?: (startEditing: boolean) => void
}

const EditableTextField: React.FunctionComponent<EditableTextFieldProps> = ({
  value,
  onChange,
  disabled,
  onChangeIsEditing,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing, inputRef.current])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const handleChangeIsEditing = (startEditing: boolean) => {
    setIsEditing(startEditing)
    onChangeIsEditing?.(startEditing)
  }

  const enabled = value != undefined && !disabled

  return (
    <EditableField
      {...props}
      disabled={!enabled}
      isEditing={isEditing}
      errorMessage={props.errorMessage}
      value={value ?? ""}
      onChangeIsEditing={handleChangeIsEditing}
    >
      <InputBox
        id={props.label}
        onBlur={() => handleChangeIsEditing(false)}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        error={props.errorMessage !== undefined}
        ref={inputRef}
      />
    </EditableField>
  )
}

export default EditableTextField

/**
 * This file contains interfaces for editing edge config values. Instances of
 * such interfaces are meant to be consumed by UI components which will use
 * the editor interface in order to retrieve, update and validate user input of
 * the config value.
 */

export type ParseResult<T> = { result: "success"; value: T } | { result: "fail"; errorMessage: string }

interface Editor<T> {
  /**
   * The current value of the editor
   */
  value: T
  /**
   * The latest known value applied to the device. This differs from
   * {@link value} if the end-user has changed the value but not pushed the
   * updated value to the device.
   */
  deviceValue: T
  /**
   * Updates the value of an editor given a new value
   */
  setValue(value: T): void
  /**
   * If true, the user has just discarded all values. Any config config change
   * for any values will yield a false value. The value is primarly used for
   * discarding temporary state in UI editors.
   */
  discarded: boolean
}

export interface EditorStringInput<T> extends Editor<T> {
  /**
   * Used to parse the end-user input. The result is either a successfully
   * parsed value, or an error containing a string message explaining the
   * issue, see ParseResult.
   *
   * @returns ParseResult<T>
   */
  parseString(value: string): ParseResult<T>
  /**
   * The readable value of the value
   */
  display(value: T): string
  /**
   * The end-user enters an invalid string to the value.
   *
   * @param errorMessage is the error message proided to the end-user
   */
  setInvalidValue(errorMessage: string): void
}

interface EnumEditorGenric<T> extends Editor<T> {
  /**
   * The available choices for the enum value.
   *
   * @returns all enum choices
   */
  choices(): T[]
  /**
   * The readable value of the value
   */
  display(value: T): string
}

export type EnumEditor<T> = EnumEditorGenric<T>
export type StringEditor = EditorStringInput<string>
export type NumberEditor = EditorStringInput<number>
export type BoolEditor = Editor<boolean>

export function boolToEnumEditor<T>(
  editor: BoolEditor,
  isFalse: T,
  isTrue: T,
  display: (value: T) => string,
): EnumEditor<T> {
  const fromValue = (value: boolean) => (value ? isTrue : isFalse)
  return {
    value: fromValue(editor.value),
    deviceValue: fromValue(editor.deviceValue),
    discarded: editor.discarded,
    setValue(value: T): void {
      if (value === isTrue) {
        editor.setValue(true)
      } else {
        editor.setValue(false)
      }
    },
    choices(): T[] {
      return [isFalse, isTrue]
    },
    display,
  }
}

import * as React from "react"
import Modal from "../../molecules/modals/Modal"
import { AnimatePresence } from "framer-motion"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer, Spacer } from "../../atoms/spacers"
import BodyTextLarge from "../../atoms/typography/BodyTextLarge"
import BodyTextMedium from "../../atoms/typography/BodyTextMedium"
import PrimaryButton from "../../atoms/buttons/PrimaryButton"
import H2 from "../../atoms/typography/H2"
import { theme } from "../../theme"
import FileUploadIcon from "../../atoms/icons/FileUploadIcon"

interface SoftwareUpdateModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (file: File | null) => void
  isLocalUpdate?: boolean
  version?: string
}

const SoftwareUpdateModal: React.FunctionComponent<SoftwareUpdateModalProps> = ({
  open,
  onClose,
  onConfirm,
  version,
  isLocalUpdate = false,
}) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const handleUploadFileClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  return (
    <AnimatePresence mode="wait">
      {open && (
        <Modal
          title={isLocalUpdate ? "Install local software update" : "Download and install software update"}
          onClose={onClose}
          closeIcon
        >
          <HorizontalSpacer spacing={2} />
          <Row>
            <Col>
              <BodyTextLarge>
                <span>This may take a short while after which the terminal will reboot. </span>
                {!isLocalUpdate && "Note that downloading the software update consumes data. "}
                Make sure the terminal is connected to a power source for the duration of the installation.
              </BodyTextLarge>
              <HorizontalSpacer spacing={2} />
              <BodyTextLarge>Any unsaved changes will be discarded.</BodyTextLarge>
              <HorizontalSpacer spacing={2} />
            </Col>
          </Row>
          {!isLocalUpdate ? (
            <Row style={{ margin: 0 }} align="center">
              <H2 color={theme.colors.greyDarker}>Software version:</H2>
              <Spacer spacing={1.5} />
              <BodyTextMedium style={{ fontStyle: "italic" }}>{version}</BodyTextMedium>
            </Row>
          ) : (
            <Row style={{ margin: 0 }} align="center" justify="between">
              <div style={{ display: "flex", alignItems: "center" }}>
                <H2 color={theme.colors.greyDarker}>Selected file:</H2>
                <Spacer spacing={1.5} />
                <BodyTextMedium style={{ fontStyle: "italic" }}>
                  {selectedFile ? selectedFile.name : "choose file..."}
                </BodyTextMedium>
              </div>
              <PrimaryButton
                size="small"
                variant="light"
                iconLeft={<FileUploadIcon />}
                title="Browse"
                onClick={handleUploadFileClick}
              />
              <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
            </Row>
          )}

          <HorizontalSpacer spacing={2} />
          <Row align="center" justify="end">
            <Col width={"fit-content"}>
              <PrimaryButton variant="light" title="Cancel" onClick={onClose} />
            </Col>
            <Col width={"fit-content"}>
              <PrimaryButton title="Install" onClick={() => onConfirm(selectedFile)} disabled={selectedFile === null} />
            </Col>
          </Row>
        </Modal>
      )}
    </AnimatePresence>
  )
}

export default SoftwareUpdateModal

// Simplified wrapper over protobuf metric to simplify fetching of metrics

import { DeviceMetadata, MetricValueMetadata } from "./DeviceMetadata"
import * as metricsProto from "edge-proto/dist/edge/v1/metrics"
import {
  EdgeError,
  MetricValueError,
  MissingMetadataError,
  ProtoMissingFieldError,
  UnexpectedMetricValueError,
} from "./Errors"
import { PathResult } from "./PathError"
import dayjs from "dayjs"

type MetricValuesContainer = { [key: string]: metricsProto.MetricGroupValues }

interface MetricValue {
  // fromProto should only be used for compound types (list and objects). For
  // other types, use another appriopiate functions defined in this interface.
  fromProto<T>(parser: (metricValue: metricsProto.MetricValue) => PathResult<T>): T | UnexpectedMetricValueError
  toEnum<T>(): T | UnexpectedMetricValueError
  toString(): string | UnexpectedMetricValueError
  toNumber(): number | UnexpectedMetricValueError
  toBool(): boolean | UnexpectedMetricValueError
}

// Same as MetricValue interface, but returns undefined in case of any
// errors. Implementation of the interface takes an callback function
// which is called on any errors. The interface is usual for displaying
// values in react, so that no handling of errors is needed.
export interface MetricValueNoError {
  fromProto<T>(parser: (test: metricsProto.MetricValue) => PathResult<T>): T | undefined
  toEnum<T>(): T | undefined
  toString(): string | undefined
  toNumber(): number | undefined
  toBool(): boolean | undefined
}

// Contains the most recent received metric values. All operations
// on the object are immutable.
export interface Metrics {
  /**
   * Returns a metric value given a group and value id.
   *
   * @returns the metric value. The value
   * is undefined if the metric value hasn't been received from the
   * edge API yet or the metadata isn't available yet.
   */
  value(groupId: string, valueId: string): MetricValueNoError | undefined
  setMetadata(metadata: DeviceMetadata): Metrics
  // This function should be called when a new metric message is received
  // from the device and returns a new metrics set containing metrics
  // corresponding to the content of the metric message.
  update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError | MetricValueError

  useProtoValue<T>(
    groupId: string,
    id: string,
    parser: (metricValue: metricsProto.MetricValue) => PathResult<T>,
  ): T | undefined
  useStringValue(groupId: string, id: string): string | undefined
  useTimestampValue(groupId: string, id: string): dayjs.Dayjs | undefined
  useNumberValue(groupdId: string, id: string): number | undefined
  useBoolValue(groupId: string, id: string): boolean | undefined
  useEnumValue<T>(groupId: string, id: string): T | undefined
}

function metricValueToString(metricValue: metricsProto.MetricValue): string | undefined {
  const value = metricValue.value
  if (value === undefined) {
    return undefined
  }
  switch (value.$case) {
    case "stringValue":
      return value.stringValue
    case "floatValue":
      return value.floatValue.toString()
    case "boolValue":
      return value.boolValue.toString()
    case "int32Value":
      return value.int32Value.toString()
    case "uint32Value":
      return value.uint32Value.toString()
    case "timestampValue":
      return value.timestampValue.toString()
    case "listValue":
      return `[${value.listValue.values.map(metricValueToString).join(", ")}]`
    case "objectValue": {
      const kvMetricToString = (kvMetric: metricsProto.KVMetricValue) => `${kvMetric.id}: ${kvMetric.value}`
      return `{${value.objectValue.entries.map(kvMetricToString).join(", ")}}`
    }
  }
}

function metricValue(metricValueMetadata: MetricValueMetadata, metricValue: metricsProto.MetricValue): MetricValue {
  const value = metricValue.value

  const error = (msg: string): UnexpectedMetricValueError => {
    return new UnexpectedMetricValueError(`For metric ${metricValueMetadata.fullId} with value ${value}: ${msg}`)
  }

  const missingValueError = () => error("Metric value is missing in proto definition")

  const unexpectedTypeError = (expectedType: string) =>
    error(`Got unexpected type ${value?.$case}, but expected a ${expectedType}`)

  const toString = (): string | UnexpectedMetricValueError => {
    return metricValueToString(metricValue) ?? missingValueError()
  }

  const toNumber = (): number | UnexpectedMetricValueError => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "int32Value": {
        return value.int32Value
      }
      case "uint32Value": {
        return value.uint32Value
      }
      case "floatValue": {
        return value.floatValue
      }
      default:
        return unexpectedTypeError("int, uint or float")
    }
  }

  const toBool = (): boolean | UnexpectedMetricValueError => {
    if (value === undefined) {
      return missingValueError()
    }
    switch (value.$case) {
      case "boolValue": {
        return value.boolValue
      }
      default:
        return unexpectedTypeError("bool")
    }
  }

  return {
    fromProto: <T>(parser: (metricValue: metricsProto.MetricValue) => PathResult<T>) => {
      const parsedValue = parser(metricValue)
      if (parsedValue.type === "error") {
        return error(`${parsedValue.message} [path: ${parsedValue.path}]`)
      }
      return parsedValue.value
    },
    toString,
    toEnum<T>(): T | UnexpectedMetricValueError {
      const validator = metricValueMetadata.validator
      const stringValue = toString()

      if (stringValue instanceof UnexpectedMetricValueError) {
        return stringValue
      }

      switch (validator.$case) {
        case "enum":
          if (!validator.isValid(stringValue)) {
            return error(`Expected enum value to be one of ${validator.expectedValues}`)
          }
          return stringValue as T
        default:
          return unexpectedTypeError("enum")
      }
    },
    toNumber,
    toBool,
  }
}

function metricValueNoError(valueMetric: MetricValue, onError: (error: EdgeError) => void): MetricValueNoError {
  return {
    toString(): string | undefined {
      const value = valueMetric.toString()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    fromProto: <T>(parser: (metricValue: metricsProto.MetricValue) => PathResult<T>) => {
      const value = valueMetric.fromProto(parser)
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toEnum<T>(): T | undefined {
      const value = valueMetric.toEnum<T>()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toNumber(): number | undefined {
      const value = valueMetric.toNumber()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
    toBool(): boolean | undefined {
      const value = valueMetric.toBool()
      if (value instanceof UnexpectedMetricValueError) {
        onError(value)
        return undefined
      }
      return value
    },
  }
}

function newMetricsWithValues(
  values: MetricValuesContainer,
  onError: (error: EdgeError) => void,
  metadata?: DeviceMetadata,
): Metrics {
  const valueWithError = function (
    groupId: string,
    valueId: string,
  ): MetricValue | undefined | MissingMetadataError | ProtoMissingFieldError {
    if (metadata === undefined) {
      return undefined
    }
    const metricMessage = values[groupId]
    if (metricMessage === undefined) {
      return undefined
    }
    const metricGroup = metadata.metricGroup(groupId)
    if (metricGroup instanceof MissingMetadataError) {
      return metricGroup
    }
    const metricValueMetadata = metricGroup.value(valueId)
    if (metricValueMetadata === undefined) {
      return new MissingMetadataError(`Could not find metric in group ${groupId} with id ${valueId}`)
    }
    const metricValues = metricMessage.metricValues
    if (metricValues === undefined) {
      return new ProtoMissingFieldError("MetricMesssage", "MetricGroup")
    }
    return metricValue(metricValueMetadata, metricValues[metricValueMetadata.index])
  }

  const getValue = (groupId: string, valueId: string): MetricValueNoError | undefined => {
    const value = valueWithError(groupId, valueId)
    if (value instanceof EdgeError) {
      onError(value)
      return undefined
    }
    if (value === undefined) {
      return undefined
    }
    return metricValueNoError(value, onError)
  }

  return {
    value: getValue,
    setMetadata(metadata: DeviceMetadata): Metrics {
      return newMetricsWithValues(values, onError, metadata)
    },
    update(metricMessage: metricsProto.MetricMessage): Metrics | ProtoMissingFieldError | MetricValueError {
      const anyMetricMessage = metricMessage.metricMessage
      if (anyMetricMessage === undefined) {
        return new ProtoMissingFieldError("MetricMessage", "metric_message")
      }
      switch (anyMetricMessage.$case) {
        case "metricSet": {
          const metricGroup = anyMetricMessage.metricSet
          if (metricGroup === undefined) {
            return new ProtoMissingFieldError("MetricMessage", "MetricGroup")
          }
          return newMetricsWithValues({ ...values, ...{ [metricGroup.id]: metricGroup } }, onError, metadata)
        }
        case "error": {
          return new MetricValueError(anyMetricMessage.error.errorMessage, anyMetricMessage.error.issue)
        }
      }
    },
    useStringValue(groupId: string, id: string): string | undefined {
      return getValue(groupId, id)?.toString()
    },
    useProtoValue<T>(
      groupId: string,
      id: string,
      parser: (metricValue: metricsProto.MetricValue) => PathResult<T>,
    ): T | undefined {
      return getValue(groupId, id)?.fromProto<T>(parser)
    },
    useTimestampValue(groupId: string, id: string): dayjs.Dayjs | undefined {
      const timestampStr = getValue(groupId, id)?.toString()
      if (timestampStr === undefined) {
        return undefined
      }
      return dayjs(timestampStr)
    },
    useNumberValue(groupId: string, id: string): number | undefined {
      return getValue(groupId, id)?.toNumber()
    },
    useBoolValue(groupId: string, id: string): boolean | undefined {
      return getValue(groupId, id)?.toBool()
    },
    useEnumValue<T>(groupId: string, id: string): T | undefined {
      return getValue(groupId, id)?.toEnum<T>()
    },
  }
}

export function newMetrics(onError: (error: EdgeError) => void): Metrics {
  return newMetricsWithValues({}, onError, undefined)
}

/* eslint-disable react/jsx-max-depth */
import React, { useState } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./components/theme"
import Header from "./components/organisms/Header"
import Footer from "./components/organisms/Footer"
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom"
import { setConfiguration, ScreenClassProvider, Col, Row } from "react-grid-system"
import styled from "styled-components"
import { Breakpoints } from "./utils/design-helpers"
import { createGlobalStyle } from "styled-components"
import LoginPage from "./components/templates/LoginPage"
import HomePage from "./components/templates/HomePage"
import { EdgeProvider, useEdgeApi } from "./edge/EdgeProvider"
import SupportPage from "./components/templates/SupportPage"
import VerticalNavbar from "./components/organisms/VerticalNavbar"
import "./App.css"
import { useMeasure } from "./utils/layout-helpers"
import SystemPage from "./components/templates/SystemPage"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./redux/store"
import LoginProvider, { useLogin } from "./auth/LoginProvider"
import ApplyConfigBanner from "./components/organisms/ApplyConfigBanner"
import { BEAM_SWITCH_NAMESPACE } from "./components/organisms/modals/BeamSwitchModal"
import LoadingBanner from "./components/molecules/LoadingBanner"

interface ProviderWrappersProps {
  children: React.ReactNode
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.greyLighter};
  max-width: 100vw;
`

const ProviderWrappers: React.FC<ProviderWrappersProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <ScreenClassProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LoginProvider>
                <EdgeProvider>{children}</EdgeProvider>
              </LoginProvider>
            </PersistGate>
          </Provider>
        </ScreenClassProvider>
      </Wrapper>
    </ThemeProvider>
  )
}

const PrivateRoute = () => {
  const { isLoggedIn } = useLogin()
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

const ConfigurablePageWrapper: React.FC<{
  children: React.ReactNode
  headerHeight: number
  beamSwitchModalOpen: boolean
}> = ({ children, headerHeight, beamSwitchModalOpen }) => {
  const { uiConfig } = useEdgeApi()

  const beamState = uiConfig.state(BEAM_SWITCH_NAMESPACE)

  return (
    <>
      {beamState.state === "push" && !beamSwitchModalOpen ? (
        <LoadingBanner headerHeight={headerHeight} text="Switching beam.." />
      ) : (
        <ApplyConfigBanner uiConfig={uiConfig} headerHeight={headerHeight} />
      )}
      {children}
    </>
  )
}

function App() {
  const [headerRef, headerHeight] = useMeasure("height")
  const [openNavbar, setOpenNavbar] = useState(false)

  const HeaderWithLogin = () => {
    const { isLoggedIn } = useLogin()
    return <Header ref={headerRef} isLoggedIn={isLoggedIn} setOpenNavbar={setOpenNavbar} openNavbar={openNavbar} />
  }

  const VerticalNavbarWithLogin = () => {
    const { isLoggedIn } = useLogin()
    return <VerticalNavbar isLoggedIn={isLoggedIn} top={headerHeight} openNavbar={openNavbar} />
  }

  const MainContent = () => {
    const { loading } = useLogin()
    const [beamSwitchModalOpen, setBeamSwitchModalOpen] = React.useState(false)

    if (loading) return null // We could potentially have a loading spinner here, or a splash screen

    setConfiguration({ containerWidths: Object.values(Breakpoints) })

    const configPage = (page: React.ReactNode) => {
      return (
        <ConfigurablePageWrapper headerHeight={headerHeight} beamSwitchModalOpen={beamSwitchModalOpen}>
          {page}
        </ConfigurablePageWrapper>
      )
    }

    return (
      <BrowserRouter>
        <HeaderWithLogin />
        <Row nogutter>
          <Col xs="content">
            <VerticalNavbarWithLogin />
          </Col>
          <Col>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route
                  index
                  element={configPage(
                    <HomePage
                      headerHeight={headerHeight}
                      beamSwitchModalOpen={beamSwitchModalOpen}
                      setBeamSwitchModalOpen={setBeamSwitchModalOpen}
                    />,
                  )}
                />
                <Route path="support" element={configPage(<SupportPage />)} />
                <Route path="system" element={configPage(<SystemPage headerHeight={headerHeight} />)} />
              </Route>
              <Route path="/login" element={<LoginPage />} />
            </Routes>
            <Footer />
          </Col>
        </Row>
      </BrowserRouter>
    )
  }

  return (
    <ProviderWrappers>
      <MainContent />
    </ProviderWrappers>
  )
}

export default App

import { AnimatePresence } from "framer-motion"
import * as React from "react"
import { Row, Col } from "react-grid-system"
import { HorizontalSpacer } from "../../atoms/spacers"
import Modal from "../../molecules/modals/Modal"
import IconContainer from "../../atoms/icons/IconContainer"
import SatelliteIcon from "../../atoms/icons/SatelliteIcon"
import TooltipRow from "../../molecules/card/TooltipRow"
import H3 from "../../atoms/typography/H3"
import styled from "styled-components"
import SmallCard from "../../atoms/card/SmallCard"
import { theme } from "../../theme"
import BeamConnectionDetails from "../BeamConnectionDetails"
import * as types from "../../../types"
import { useEdgeApi } from "../../../edge/EdgeProvider"
import { formatOrbitalSlot, freqToString, orientationToString } from "../../../utils/formatters"

export const BEAM_SWITCH_CONFIG_ORIGIN = "Beam"
export const BEAM_SWITCH_NAMESPACE = "beam_switch"

const BEAM_SWITCH_TOOLTIP = `
A beam is a signal which a satellite transmits. Depending on your conditions, 
the auto-selected beam may not be ideal for you. 
If so you can switch to another beam in this list.
`

interface BeamSwitchModalProps {
  open: boolean
  onClose: () => void
  beams: types.BeamList | undefined
}

interface BeamListProps {
  beams?: types.BeamList
  onSelectBeam: (id: number) => void
  selectedBeam?: number
  connectedBeam?: number
  switchingToBeam?: number
}

const BeamListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  row-gap: 12px;

  // https://stackoverflow.com/a/63514064
  overflow-y: scroll;
  height: 20rem;
  min-height: 100%;
`

const BeamList = ({ beams, onSelectBeam, selectedBeam, connectedBeam, switchingToBeam }: BeamListProps) => (
  <BeamListContainer>
    {beams?.map((beam) => (
      <SmallCard
        clickable
        selected={selectedBeam === beam.id}
        key={beam.id}
        onClick={() => {
          onSelectBeam(beam.id)
        }}
      >
        <Row justify="between">
          <H3>{beam.name}</H3>
          {!switchingToBeam && connectedBeam === beam.id && <H3 color={theme.colors.greenDark}>Connected</H3>}
          {switchingToBeam === beam.id && <H3 color={theme.colors.greyDark}>Connecting..</H3>}
        </Row>
      </SmallCard>
    ))}
  </BeamListContainer>
)

const BeamSwitchModal: React.FunctionComponent<BeamSwitchModalProps> = ({ open, onClose, beams }) => {
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const { metrics, config, uiConfig } = useEdgeApi()
  const beamIdConfigEditor = config.useBeamInfoId(BEAM_SWITCH_CONFIG_ORIGIN, BEAM_SWITCH_NAMESPACE)
  const selectedBeam = beams?.find((beam) => beam.id === beamIdConfigEditor?.value)
  const setSelectedBeam = beamIdConfigEditor?.setValue ?? (() => undefined)
  const connectedBeam = beams?.find((beam) => beam.id === beamIdConfigEditor?.deviceValue)
  const configState = uiConfig.state(BEAM_SWITCH_NAMESPACE)

  // Currently we don't have satellite metadata for non selected satellites, but
  // we provision the metadata anyway in the meantime.
  let beamDetails = [
    { label: "Orbital slot", value: "?" },
    { label: "Azimuth", value: "?" },
    { label: "Elevation", value: "?" },
    { label: "Polarization", value: "?" },
    { label: "Rx bandwidth", value: "?" },
    { label: "Rx RF frequency", value: "?" },
    { label: "Rx LO frequency", value: "?" },
  ]
  if (beamIdConfigEditor?.value !== undefined && beamIdConfigEditor.value === beamIdConfigEditor.deviceValue) {
    beamDetails = [
      { label: "Orbital slot", value: formatOrbitalSlot(metrics.useSatellitePositionLon()) ?? "?" },
      { label: "Azimuth", value: orientationToString(metrics.useTargetOrientationAzimuth()) ?? "?" },
      { label: "Elevation", value: orientationToString(metrics.useTargetOrientationElevation()) ?? "?" },
      { label: "Polarization", value: orientationToString(metrics.useTargetOrientationPolarization()) ?? "?" },
      { label: "Rx bandwidth", value: freqToString(metrics.useCarrierBandwidth(), "MHz") ?? "?" },
      { label: "Rx RF frequency", value: freqToString(metrics.useCarrierCarrier(), "MHz") ?? "?" },
      { label: "Rx LO frequency", value: freqToString(metrics.useCarrierRxLo(), "GHz") ?? "?" },
    ]
  }
  let switchingBeam: types.Beam | undefined = undefined
  if (
    configState.state === "push" &&
    configState.values.find((value) => value.progressData.extraData === BEAM_SWITCH_CONFIG_ORIGIN) !== undefined
  ) {
    switchingBeam = selectedBeam
  }

  const selectBeam = (id: number) => {
    setSelectedBeam(id)
  }

  const connectSelectedBeam = () => {
    uiConfig.pushToDevice(BEAM_SWITCH_NAMESPACE)
  }

  const handleClickToolTip = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpenTooltip(!openTooltip)
  }

  const ModalIcon = () => (
    <IconContainer onClickHelp={handleClickToolTip}>
      <SatelliteIcon />
    </IconContainer>
  )

  const connectionStatus =
    switchingBeam !== undefined ? "connecting" : connectedBeam?.id === selectedBeam?.id ? "on" : "off"

  return (
    <AnimatePresence mode="wait">
      {open && (
        <Modal
          title="Beam list"
          onClose={onClose}
          closeIcon
          leftIcon={<ModalIcon />}
          centerTitle={false}
          fixedWidth={true}
        >
          <TooltipRow visible={openTooltip} tooltip={BEAM_SWITCH_TOOLTIP} />
          <HorizontalSpacer spacing={1} />
          <H3>Available beams</H3>
          <HorizontalSpacer spacing={1} />
          <Row align="stretch" style={{ rowGap: "24px" }}>
            <Col xs={12} lg={6}>
              <BeamList
                beams={beams}
                connectedBeam={connectedBeam?.id}
                selectedBeam={selectedBeam?.id}
                switchingToBeam={switchingBeam?.id}
                onSelectBeam={selectBeam}
              />
            </Col>
            <Col>
              <BeamConnectionDetails
                name={selectedBeam?.name ?? ""}
                details={beamDetails}
                onConnectBeam={connectSelectedBeam}
                status={connectionStatus}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </AnimatePresence>
  )
}

export default BeamSwitchModal

import * as protoSoftwareUpdate from "edge-proto/dist/edge/v1/software_update"
import { ProtoMissingFieldError, SoftwareUpdateError } from "./Errors"
import { FileReference } from "./FileUpload"

export type SoftwareUpdateProgress =
  | "begin"
  | "upload_file"
  | "download_software"
  | "complete_download_software"
  | "reboot_device"
  | "unknown"

export interface SoftwareUpdateState {
  progress(): SoftwareUpdateProgress
  setUploadFileProgress(): SoftwareUpdateState
  update(
    msg: protoSoftwareUpdate.SoftwareUpdateMessage,
  ): SoftwareUpdateState | SoftwareUpdateError | ProtoMissingFieldError
  newVersion: string | undefined
}

function newSoftwareUpdateStateWithValues(
  progress: SoftwareUpdateProgress,
  version: string | undefined,
): SoftwareUpdateState {
  const changeProgress = (progress: SoftwareUpdateProgress) => newSoftwareUpdateStateWithValues(progress, version)
  return {
    progress(): SoftwareUpdateProgress {
      return progress
    },
    setUploadFileProgress(): SoftwareUpdateState {
      return changeProgress("upload_file")
    },
    update(
      softwareUpdateMessage: protoSoftwareUpdate.SoftwareUpdateMessage,
    ): SoftwareUpdateState | SoftwareUpdateError | ProtoMissingFieldError {
      const message = softwareUpdateMessage.message
      if (message === undefined) {
        return new ProtoMissingFieldError("SoftwareUpdateMessage", "message")
      }
      switch (message.$case) {
        case "metadata": {
          const metadata = message.metadata
          if (metadata === undefined) {
            return new ProtoMissingFieldError("SoftwareUpdateMetadata.message", "metadata")
          }
          const softwareVersion = metadata.softwareVersion
          if (softwareVersion === undefined) {
            return new ProtoMissingFieldError("SoftwareUpdateMetadata", "software_version")
          }
          return newSoftwareUpdateStateWithValues(progress, softwareVersion.version)
        }
        case "state": {
          const progress = message.state.progress
          switch (progress) {
            case protoSoftwareUpdate.SoftwareUpdateProgress.SOFTWARE_UPDATE_PROGRESS_UNSPECIFIED:
              return changeProgress("unknown")
            case protoSoftwareUpdate.SoftwareUpdateProgress.SOFTWARE_UPDATE_PROGRESS_BEGIN:
              return changeProgress("begin")
            case protoSoftwareUpdate.SoftwareUpdateProgress.SOFTWARE_UPDATE_PROGRESS_BEGIN_DOWNLOAD_SOFTWARE:
              return changeProgress("download_software")
            case protoSoftwareUpdate.SoftwareUpdateProgress.SOFTWARE_UPDATE_PROGRESS_COMPLETE_DOWNLOAD_SOFTWARE:
              return changeProgress("complete_download_software")
            case protoSoftwareUpdate.SoftwareUpdateProgress.SOFTWARE_UPDATE_PROGRESS_REBOOT_DEVICE:
              return changeProgress("reboot_device")
            case protoSoftwareUpdate.SoftwareUpdateProgress.UNRECOGNIZED:
              return changeProgress("unknown")
            default:
              return progress satisfies never
          }
        }
        case "error": {
          const reason = message.error.reason
          const error = (reason: string) =>
            new SoftwareUpdateError(`The software update failed since ${reason}: ${message.error.errorMessage}`)
          const errorUnknown = new SoftwareUpdateError(
            `The software update failed for unknown reason: ${message.error.errorMessage}`,
          )
          switch (reason) {
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_UNSPECIFIED:
              return errorUnknown
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_DOWNLOAD_FAIL:
              return error("the download of the software update bundle failed")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_CONCURRENT_CONFIG_UPDATE:
              return error("a concurrent config update is in progress")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_CONCURRENT_SOFTWARE_UPDATE:
              return error("a concurrent software update is in progress")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_CONCURRENT_FACTORY_RESET:
              return error("a concurrent factory reset is in progress")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_BAD_SIGNATURE:
              return error("the provided software bundle contained a bad signature")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_VERSION_NOT_FOUND:
              return error("the provided software version could not be found")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.SOFTWARE_UPDATE_ERROR_REASON_FILE_NOT_FOUND:
              return error("the provided software update bundle could not be found")
            case protoSoftwareUpdate.SoftwareUpdateErrorReason.UNRECOGNIZED:
              return errorUnknown
          }
        }
      }
    },
    newVersion: version,
  }
}

export function newSoftwareUpdateState(progress: SoftwareUpdateProgress): SoftwareUpdateState {
  return newSoftwareUpdateStateWithValues(progress, undefined)
}

export function clientSoftwareUpdateMessage(software: FileReference): protoSoftwareUpdate.ClientSoftwareUpdateMessage {
  return protoSoftwareUpdate.ClientSoftwareUpdateMessage.create({
    message: {
      fileUpdateRequest: {
        fileReference: {
          reference: software,
        },
      },
      $case: "fileUpdateRequest",
    },
  })
}

import React from "react"
import Banner, { BannerProps } from "../atoms/Banner"
import { Row, Col } from "react-grid-system"
import SpinnerIcon from "../atoms/icons/SpinnerIcon"
import H3 from "../atoms/typography/H3"

const LoadingBanner: React.FunctionComponent<BannerProps & { text: string }> = ({ children, headerHeight, text }) => {
  return (
    <Banner headerHeight={headerHeight}>
      {" "}
      <Row justify="center" align="center">
        <Col xs="content">
          <SpinnerIcon />
        </Col>
        <Col xs="content">
          <H3>{text}</H3>
        </Col>
      </Row>{" "}
      {children}
    </Banner>
  )
}

export default LoadingBanner

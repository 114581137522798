import * as React from "react"
import SettingsFieldWithLabel from "../../atoms/card/SettingsFieldWithLabel"
import H3 from "../../atoms/typography/H3"

interface ReadOnlyTextFieldProps {
  label: string
  value?: string
  secondValue?: string
  placeholder?: string
  secondPlaceholder?: string
  compact?: boolean
  textAlign?: "left" | "center" | "right"
}

const ReadOnlyTextField: React.FunctionComponent<ReadOnlyTextFieldProps> = ({
  label,
  value,
  secondValue,
  textAlign = "left",
  compact,
  placeholder,
  secondPlaceholder,
}) => {
  const useSecondValue = secondValue !== null || secondPlaceholder !== null
  return (
    <SettingsFieldWithLabel label={label} compact={compact} alignLabelContent="start">
      <H3 textAlign={textAlign} disabled={value == undefined} data-testid={`${label}-value`}>
        {value ?? placeholder}
        {useSecondValue && (
          <>
            <br />
            {secondPlaceholder === "" ? <br /> : <></>}
            {secondValue ?? secondPlaceholder}
          </>
        )}
      </H3>
    </SettingsFieldWithLabel>
  )
}

export default ReadOnlyTextField

import * as React from "react"
import { Row, Col } from "react-grid-system"
import { DeviceMetrics, useEdgeApi } from "../../edge/EdgeProvider"
import { AntennaOrientation } from "../../types"
import { HorizontalSpacer } from "../atoms/spacers"
import SatelliteSignalCard from "../organisms/overview/SatelliteSignalCard"
import SystemMessagesCard from "../organisms/overview/SystemMessagesCard"
import WifiCard from "../organisms/overview/WifiCard"
import PositionCard from "../organisms/overview/PositionCard"
import TransmissionCard from "../organisms/overview/TransmissionCard"
import PageContainer from "../atoms/PageContainer"
import { DeepPartial } from "edge-proto/dist/edge/v1/metrics"
import { signalStrength } from "../../utils/signal-strength"
import { BeamListEntry } from "../../edge/device_gen/motion"
import { formatOrbitalSlot } from "../../utils/formatters"

const currentOrientation = (metrics: DeviceMetrics): DeepPartial<AntennaOrientation> => {
  return {
    polarization: {
      value: metrics.useCurrentOrientationPolarization(),
      orientation: undefined,
    },
    azimuth: metrics.useCurrentOrientationAzimuth(),
    elevation: metrics.useCurrentOrientationElevation(),
  }
}

const targetOrientation = (metrics: DeviceMetrics): DeepPartial<AntennaOrientation> => {
  return {
    polarization: {
      value: metrics.useTargetOrientationPolarization(),
      orientation: undefined,
    },
    azimuth: metrics.useTargetOrientationAzimuth(),
    elevation: metrics.useTargetOrientationElevation(),
  }
}

const HomePage: React.FunctionComponent<{
  headerHeight: number
  beamSwitchModalOpen: boolean
  setBeamSwitchModalOpen: (open: boolean) => void
}> = ({ beamSwitchModalOpen, setBeamSwitchModalOpen }) => {
  const { metrics, config } = useEdgeApi()

  const transmissionEnabled = config.useTransmissionIntentEnabled("Transmission")

  const cOverN = metrics.useSignalStatusStrength()

  const noset = () => {
    return
  }

  const beams = metrics.useBeamList()
  const satelliteLongitude = metrics.useSatellitePositionLon()
  const beamIndex = config.useBeamInfoId("")?.value
  const selectedBeam = beams?.find((beam: BeamListEntry) => beam.id === beamIndex)

  return (
    <>
      <PageContainer>
        <HorizontalSpacer spacing={4} />
        <Row>
          <Col>
            <SatelliteSignalCard
              currentOrientation={currentOrientation(metrics)}
              targetOrientation={targetOrientation(metrics)}
              cOverN={cOverN}
              signalStrength={cOverN ? signalStrength(cOverN) : undefined}
              beams={beams}
              beamId={selectedBeam?.name}
              longitudeSatellite={formatOrbitalSlot(satelliteLongitude)}
              rf={{ rx: metrics.useCarrierCarrier() }}
              bandwidth={{ rx: metrics.useCarrierBandwidth() }}
              lo={{ rx: metrics.useCarrierRxLo(), tx: metrics.useCarrierTxLo() }}
              beamSwitchModalOpen={beamSwitchModalOpen}
              setBeamSwitchModalOpen={setBeamSwitchModalOpen}
            />
            <HorizontalSpacer spacing={2} />
            <SystemMessagesCard />
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <PositionCard />
            <HorizontalSpacer spacing={2} />
          </Col>
          <Col>
            <WifiCard />
          </Col>
        </Row>
        <HorizontalSpacer spacing={2} />
        <Row>
          <Col>
            <TransmissionCard
              active={transmissionEnabled?.value ?? false}
              onToggle={transmissionEnabled?.setValue ?? noset}
            />
          </Col>
        </Row>
        <HorizontalSpacer spacing={4} />
      </PageContainer>
    </>
  )
}

export default HomePage

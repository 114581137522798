import * as protoFileUpload from "edge-proto/dist/edge/v1/file_upload"
import { ProtoMissingFieldError } from "./Errors"

export type FileReference = string

export function parseFileUploadMessage(
  uploadFileMessage: protoFileUpload.FileUploadResultMessage,
): FileReference[] | ProtoMissingFieldError {
  const fileResult = uploadFileMessage.fileUploadResult
  if (fileResult === undefined) {
    return new ProtoMissingFieldError("FileUploadResultMessage", "file_upload_result")
  }
  return fileResult.fileReference.map((fileRef) => fileRef.reference)
}

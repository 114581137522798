import * as React from "react"
import { Row } from "react-grid-system"
import PrimaryButton from "../atoms/buttons/PrimaryButton"
import SmallCard from "../atoms/card/SmallCard"
import ChangeCircleIcon from "../atoms/icons/ChangeCircleIcon"
import SpinnerIcon from "../atoms/icons/SpinnerIcon"
import H3 from "../atoms/typography/H3"
import ReadOnlyTextField from "../molecules/card/ReadOnlyTextField"
import { theme } from "../theme"

interface BeamConnectionStatusProps {
  status: "on" | "connecting" | "off"
  onClick: () => void
}

interface BeamConnectionDetailsProps {
  status: "on" | "connecting" | "off"
  name: string
  onConnectBeam: (beamName: string) => void
  details: Pick<React.ComponentProps<typeof ReadOnlyTextField>, "label" | "value">[]
}

const BeamConnectionStatusLabel = ({ status, onClick }: BeamConnectionStatusProps) => {
  switch (status) {
    case "off":
      return <PrimaryButton title="Switch to this beam" iconLeft={<ChangeCircleIcon />} onClick={() => onClick()} />
    case "on":
      return (
        <Row align="center" justify="center" style={{ height: "58px" }}>
          <H3 color={theme.colors.greenDark}>● Connected beam</H3>
        </Row>
      )
    case "connecting":
      return (
        <Row align="center" justify="center" style={{ height: "58px" }}>
          <SpinnerIcon />
          <H3 style={{ padding: theme.spacings.large }}>Switching beam...</H3>
        </Row>
      )
  }
}

const BeamConnectionDetails: React.FunctionComponent<BeamConnectionDetailsProps> = ({
  name,
  status,
  details,
  onConnectBeam,
}) => {
  return (
    <SmallCard backgroundColor={theme.colors.background} selected>
      <Row align="stretch" justify="between" direction="column" style={{ gap: "1rem", height: "100%" }} nogutter>
        <H3 color={theme.colors.greyDarker}>{name} details</H3>
        <div>
          {details.map((values) => (
            <ReadOnlyTextField compact={true} {...values} key={values.label} textAlign="right" />
          ))}
        </div>
        <Row justify="center" align="center">
          <BeamConnectionStatusLabel onClick={() => onConnectBeam(name)} status={status} />
        </Row>
      </Row>
    </SmallCard>
  )
}

export default BeamConnectionDetails

import React from "react"
import styled from "styled-components"

export interface BannerProps extends React.PropsWithChildren {
  headerHeight: number
}

const SoftwareDownloadBannerContainer = styled.div<{ top: number }>`
  top: ${({ top }) => top}px;
  position: sticky;
  z-index: 1;
  border-bottom: 4px solid ${({ theme }) => theme.gradients.blueBorder};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(119, 119, 119, 0.25);
  padding: 20px 30px;
`

const Banner: React.FunctionComponent<BannerProps> = ({ children, headerHeight }) => {
  return <SoftwareDownloadBannerContainer top={headerHeight}>{children}</SoftwareDownloadBannerContainer>
}

export default Banner

// Auto generated file, please don't edit manually

import { UIConfig } from "../UIConfig"
import { NamespaceId } from "../Config"
import { Metrics } from "../Metrics"
import { StringEditor, EnumEditor, NumberEditor, BoolEditor } from "../editor"
import dayjs from "dayjs"
import * as metricsProto from "edge-proto/dist/edge/v1/metrics"
import { PathResult, pathErrorInit, pathErrorAddPrefix, pathSuccessInit } from "../PathError"

export type FanControlMode = "off" | "auto" | "max"
export type WifiSecurity = "open" | "wpa-psk" | "wpa2-psk" | "wpa3-psk"

export interface DeviceConfigMotion<T> {
  useBeamInfoId(extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useFanControlMode(extraData: T, namespace?: NamespaceId): EnumEditor<FanControlMode> | undefined
  useModemUserPassword(extraData: T, namespace?: NamespaceId): StringEditor | undefined
  useManualPositionEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined
  useManualPositionLat(extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useManualPositionLon(extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useTransmissionIntentEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined
  useWifiEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined
  useWifiTxPower(extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useWifiChannel(extraData: T, namespace?: NamespaceId): NumberEditor | undefined
  useWifiSsid(extraData: T, namespace?: NamespaceId): StringEditor | undefined
  useWifiBroadcastSsid(extraData: T, namespace?: NamespaceId): BoolEditor | undefined
  useWifiSecurity(extraData: T, namespace?: NamespaceId): EnumEditor<WifiSecurity> | undefined
  useWifiPassword(extraData: T, namespace?: NamespaceId): StringEditor | undefined
}

export function newDeviceConfigMotion<T>(uiConfig: UIConfig<T>): DeviceConfigMotion<T> {
  return {
    useBeamInfoId(extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      return uiConfig.useNumberValue("beam_info_id", extraData, namespace)
    },
    useFanControlMode(extraData: T, namespace?: NamespaceId): EnumEditor<FanControlMode> | undefined {
      return uiConfig.useEnumValue("fan_control_mode", extraData, namespace)
    },
    useModemUserPassword(extraData: T, namespace?: NamespaceId): StringEditor | undefined {
      return uiConfig.useStringValue("modem_user_password", extraData, namespace)
    },
    useManualPositionEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined {
      return uiConfig.useBoolValue("manual_position_enabled", extraData, namespace)
    },
    useManualPositionLat(extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      return uiConfig.useNumberValue("manual_position_lat", extraData, namespace)
    },
    useManualPositionLon(extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      return uiConfig.useNumberValue("manual_position_lon", extraData, namespace)
    },
    useTransmissionIntentEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined {
      return uiConfig.useBoolValue("transmission_intent_enabled", extraData, namespace)
    },
    useWifiEnabled(extraData: T, namespace?: NamespaceId): BoolEditor | undefined {
      return uiConfig.useBoolValue("wifi_enabled", extraData, namespace)
    },
    useWifiTxPower(extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      return uiConfig.useNumberValue("wifi_tx_power", extraData, namespace)
    },
    useWifiChannel(extraData: T, namespace?: NamespaceId): NumberEditor | undefined {
      return uiConfig.useNumberValue("wifi_channel", extraData, namespace)
    },
    useWifiSsid(extraData: T, namespace?: NamespaceId): StringEditor | undefined {
      return uiConfig.useStringValue("wifi_ssid", extraData, namespace)
    },
    useWifiBroadcastSsid(extraData: T, namespace?: NamespaceId): BoolEditor | undefined {
      return uiConfig.useBoolValue("wifi_broadcast_ssid", extraData, namespace)
    },
    useWifiSecurity(extraData: T, namespace?: NamespaceId): EnumEditor<WifiSecurity> | undefined {
      return uiConfig.useEnumValue("wifi_security", extraData, namespace)
    },
    useWifiPassword(extraData: T, namespace?: NamespaceId): StringEditor | undefined {
      return uiConfig.useStringValue("wifi_password", extraData, namespace)
    },
  }
}

export type BeamListEntry = {
  id: number
  name: string
}
export type BeamList = BeamListEntry[]
export type CarrierPolarization = "H" | "V"
export type DiagnosticsHealth = "Healthy" | "Degraded" | "Critical" | "Unknown"
export type SoftwareUpdateStatus = "Pending" | "Succeeded" | "Failed: reason"
export type SoftwareUpdateType = "StaticDelta" | "Remote" | "Rollback" | "Unknown"

export interface DeviceMetricsMotion {
  useAntennaPositionLat(): number | undefined
  useAntennaPositionLon(): number | undefined
  useBeamList(): BeamList | undefined
  useBucInfoVersion(): boolean | undefined
  useBucInfoBooting(): boolean | undefined
  useBucInfoUnmute(): boolean | undefined
  useBucTemperatureTimestamp(): dayjs.Dayjs | undefined
  useBucTemperatureAmplifier1(): number | undefined
  useBucTemperatureAmplifier2(): number | undefined
  useCarrierRxLo(): number | undefined
  useCarrierTxLo(): number | undefined
  useCarrierCarrier(): number | undefined
  useCarrierBandwidth(): number | undefined
  useCarrierPolSkew(): number | undefined
  useCarrierPolarization(): CarrierPolarization | undefined
  useConnectivityPing(): boolean | undefined
  useConnectivityDns(): boolean | undefined
  useConnectivityTimestamp(): dayjs.Dayjs | undefined
  useCurrentOrientationAzimuth(): number | undefined
  useCurrentOrientationElevation(): number | undefined
  useCurrentOrientationPolarization(): number | undefined
  useDiagnosticsHealth(): DiagnosticsHealth | undefined
  useFanSpeedsPbBucFan1(): number | undefined
  useFanSpeedsPbBucFan2(): number | undefined
  useFanSpeedsPbModemFan1(): number | undefined
  useFanSpeedsPbModemFan2(): number | undefined
  useFanSpeedsUibExtFan1(): number | undefined
  useFanSpeedsUibExtFan2(): number | undefined
  useFanSpeedsUibIntFan1(): number | undefined
  useFanSpeedsUibIntFan2(): number | undefined
  useGnssSatellites(): number | undefined
  useLnbBeaconStrength(): number | undefined
  useLnbInfoVersion(): number | undefined
  useLnbInfoBooting(): boolean | undefined
  useModemInfoSerial(): number | undefined
  useModemInfoBoard(): string | undefined
  useModemInfoPlatform(): string | undefined
  useModemInfoSoftware(): string | undefined
  useMotorDiagnosticsAlphaTimestamp(): dayjs.Dayjs | undefined
  useMotorDiagnosticsAlphaSpeed(): number | undefined
  useMotorDiagnosticsAlphaCurrent(): number | undefined
  useMotorDiagnosticsAlphaWindingTemp(): number | undefined
  useMotorDiagnosticsAlphaDriveThermalState(): number | undefined
  useMotorDiagnosticsAlphaMotorThermalState(): number | undefined
  useMotorDiagnosticsBetaTimestamp(): dayjs.Dayjs | undefined
  useMotorDiagnosticsBetaSpeed(): number | undefined
  useMotorDiagnosticsBetaCurrent(): number | undefined
  useMotorDiagnosticsBetaWindingTemp(): number | undefined
  useMotorDiagnosticsBetaDriveThermalState(): number | undefined
  useMotorDiagnosticsBetaMotorThermalState(): number | undefined
  useRuntimeMonitorTimestamp(): dayjs.Dayjs | undefined
  useRuntimeMonitorCpuTemp(): number | undefined
  useRuntimeMonitorSocTemp(): number | undefined
  useRuntimeMonitorPbTemp(): number | undefined
  useRuntimeMonitorPbHumidity(): number | undefined
  useRuntimeMonitorUibTemp(): number | undefined
  useRuntimeMonitorUibHumidity(): number | undefined
  useRuntimeMonitorVoltageLevel24v(): number | undefined
  useRuntimeMonitorVoltageLevel5v(): number | undefined
  useRuntimeMonitorVoltageLevel3v3(): number | undefined
  useRuntimeMonitorVoltageLevel1v8(): number | undefined
  useRuntimeMonitorVoltageLevel1v2(): number | undefined
  useSatellitePositionLat(): number | undefined
  useSatellitePositionLon(): number | undefined
  useSignalStatusStrength(): number | undefined
  useSignalStatusReceiving(): boolean | undefined
  useSoftwareUpdateStatus(): SoftwareUpdateStatus | undefined
  useSoftwareUpdateType(): SoftwareUpdateType | undefined
  useSystemTimeTimestamp(): dayjs.Dayjs | undefined
  useTargetOrientationAzimuth(): number | undefined
  useTargetOrientationElevation(): number | undefined
  useTargetOrientationPolarization(): number | undefined
}

export function newDeviceMetricsMotion(metrics: Metrics) {
  return {
    useAntennaPositionLat(): number | undefined {
      return metrics.useNumberValue("antenna_position", "lat")
    },
    useAntennaPositionLon(): number | undefined {
      return metrics.useNumberValue("antenna_position", "lon")
    },
    useBeamList(): BeamList | undefined {
      const beamListEntryId = (value: metricsProto.MetricValue): PathResult<number> => {
        const metricValue = value.value
        switch (metricValue?.$case) {
          case "int32Value": {
            return pathSuccessInit(metricValue.int32Value)
          }
          default: {
            return pathErrorInit(`Expected int type but got ${metricValue?.$case}`, "")
          }
        }
      }
      const beamListEntryName = (value: metricsProto.MetricValue): PathResult<string> => {
        const metricValue = value.value
        switch (metricValue?.$case) {
          case "stringValue": {
            return pathSuccessInit(metricValue.stringValue)
          }
          default: {
            return pathErrorInit(`Expected string type but got ${metricValue?.$case}`, "")
          }
        }
      }
      const beamListEntry = (value: metricsProto.MetricValue): PathResult<BeamListEntry> => {
        const metricValue = value.value
        switch (metricValue?.$case) {
          case "objectValue": {
            const fields = metricValue.objectValue.entries
            // id
            const idMessage = fields.find((field: metricsProto.KVMetricValue) => field.id == "id")
            if (idMessage === undefined) {
              return pathErrorInit("object field could not be found", "id")
            }
            if (idMessage.value === undefined) {
              return pathErrorInit("proto kvmetric has undefined value", "id")
            }
            const id = beamListEntryId(idMessage.value)
            if (id.type === "error") {
              return pathErrorAddPrefix(id, "id")
            }
            // name
            const nameMessage = fields.find((field: metricsProto.KVMetricValue) => field.id == "name")
            if (nameMessage === undefined) {
              return pathErrorInit("object field could not be found", "name")
            }
            if (nameMessage.value === undefined) {
              return pathErrorInit("proto kvmetric has undefined value", "name")
            }
            const name = beamListEntryName(nameMessage.value)
            if (name.type === "error") {
              return pathErrorAddPrefix(name, "name")
            }
            return pathSuccessInit({
              id: id.value,
              name: name.value,
            })
          }
          default: {
            return pathErrorInit(`Expected object type but got ${metricValue?.$case}`, "")
          }
        }
      }
      const beamList = (value: metricsProto.MetricValue): PathResult<BeamList> => {
        const metricValue = value.value
        switch (metricValue?.$case) {
          case "listValue": {
            const values: BeamList = []
            for (let i = 0; i < metricValue.listValue.values.length; i++) {
              const mappedValue = beamListEntry(metricValue.listValue.values[i])
              if (mappedValue.type === "error") {
                return pathErrorAddPrefix(mappedValue, `[${i}]`)
              }
              values.push(mappedValue.value)
            }
            return pathSuccessInit(values)
          }
          default: {
            return pathErrorInit(`Expected list type but got ${metricValue?.$case}`, "")
          }
        }
      }
      const parser = (value: metricsProto.MetricValue) => beamList(value)
      return metrics.useProtoValue("beam_list", "list", parser)
    },
    useBucInfoVersion(): boolean | undefined {
      return metrics.useBoolValue("buc_info", "version")
    },
    useBucInfoBooting(): boolean | undefined {
      return metrics.useBoolValue("buc_info", "booting")
    },
    useBucInfoUnmute(): boolean | undefined {
      return metrics.useBoolValue("buc_info", "unmute")
    },
    useBucTemperatureTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("buc_temperature", "timestamp")
    },
    useBucTemperatureAmplifier1(): number | undefined {
      return metrics.useNumberValue("buc_temperature", "amplifier_1")
    },
    useBucTemperatureAmplifier2(): number | undefined {
      return metrics.useNumberValue("buc_temperature", "amplifier_2")
    },
    useCarrierRxLo(): number | undefined {
      return metrics.useNumberValue("carrier", "rx_lo")
    },
    useCarrierTxLo(): number | undefined {
      return metrics.useNumberValue("carrier", "tx_lo")
    },
    useCarrierCarrier(): number | undefined {
      return metrics.useNumberValue("carrier", "carrier")
    },
    useCarrierBandwidth(): number | undefined {
      return metrics.useNumberValue("carrier", "bandwidth")
    },
    useCarrierPolSkew(): number | undefined {
      return metrics.useNumberValue("carrier", "pol_skew")
    },
    useCarrierPolarization(): CarrierPolarization | undefined {
      return metrics.useEnumValue("carrier", "polarization")
    },
    useConnectivityPing(): boolean | undefined {
      return metrics.useBoolValue("connectivity", "ping")
    },
    useConnectivityDns(): boolean | undefined {
      return metrics.useBoolValue("connectivity", "dns")
    },
    useConnectivityTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("connectivity", "timestamp")
    },
    useCurrentOrientationAzimuth(): number | undefined {
      return metrics.useNumberValue("current_orientation", "azimuth")
    },
    useCurrentOrientationElevation(): number | undefined {
      return metrics.useNumberValue("current_orientation", "elevation")
    },
    useCurrentOrientationPolarization(): number | undefined {
      return metrics.useNumberValue("current_orientation", "polarization")
    },
    useDiagnosticsHealth(): DiagnosticsHealth | undefined {
      return metrics.useEnumValue("diagnostics", "health")
    },
    useFanSpeedsPbBucFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_buc_fan1")
    },
    useFanSpeedsPbBucFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_buc_fan2")
    },
    useFanSpeedsPbModemFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_modem_fan1")
    },
    useFanSpeedsPbModemFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "pb_modem_fan2")
    },
    useFanSpeedsUibExtFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_ext_fan1")
    },
    useFanSpeedsUibExtFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_ext_fan2")
    },
    useFanSpeedsUibIntFan1(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_int_fan1")
    },
    useFanSpeedsUibIntFan2(): number | undefined {
      return metrics.useNumberValue("fan_speeds", "uib_int_fan2")
    },
    useGnssSatellites(): number | undefined {
      return metrics.useNumberValue("gnss", "satellites")
    },
    useLnbBeaconStrength(): number | undefined {
      return metrics.useNumberValue("lnb_beacon", "strength")
    },
    useLnbInfoVersion(): number | undefined {
      return metrics.useNumberValue("lnb_info", "version")
    },
    useLnbInfoBooting(): boolean | undefined {
      return metrics.useBoolValue("lnb_info", "booting")
    },
    useModemInfoSerial(): number | undefined {
      return metrics.useNumberValue("modem_info", "serial")
    },
    useModemInfoBoard(): string | undefined {
      return metrics.useStringValue("modem_info", "board")
    },
    useModemInfoPlatform(): string | undefined {
      return metrics.useStringValue("modem_info", "platform")
    },
    useModemInfoSoftware(): string | undefined {
      return metrics.useStringValue("modem_info", "software")
    },
    useMotorDiagnosticsAlphaTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("motor_diagnostics_alpha", "timestamp")
    },
    useMotorDiagnosticsAlphaSpeed(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "speed")
    },
    useMotorDiagnosticsAlphaCurrent(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "current")
    },
    useMotorDiagnosticsAlphaWindingTemp(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "winding_temp")
    },
    useMotorDiagnosticsAlphaDriveThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "drive_thermal_state")
    },
    useMotorDiagnosticsAlphaMotorThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_alpha", "motor_thermal_state")
    },
    useMotorDiagnosticsBetaTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("motor_diagnostics_beta", "timestamp")
    },
    useMotorDiagnosticsBetaSpeed(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "speed")
    },
    useMotorDiagnosticsBetaCurrent(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "current")
    },
    useMotorDiagnosticsBetaWindingTemp(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "winding_temp")
    },
    useMotorDiagnosticsBetaDriveThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "drive_thermal_state")
    },
    useMotorDiagnosticsBetaMotorThermalState(): number | undefined {
      return metrics.useNumberValue("motor_diagnostics_beta", "motor_thermal_state")
    },
    useRuntimeMonitorTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("runtime_monitor", "timestamp")
    },
    useRuntimeMonitorCpuTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "cpu_temp")
    },
    useRuntimeMonitorSocTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "soc_temp")
    },
    useRuntimeMonitorPbTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "pb_temp")
    },
    useRuntimeMonitorPbHumidity(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "pb_humidity")
    },
    useRuntimeMonitorUibTemp(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "uib_temp")
    },
    useRuntimeMonitorUibHumidity(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "uib_humidity")
    },
    useRuntimeMonitorVoltageLevel24v(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_24v")
    },
    useRuntimeMonitorVoltageLevel5v(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_5v")
    },
    useRuntimeMonitorVoltageLevel3v3(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_3v3")
    },
    useRuntimeMonitorVoltageLevel1v8(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_1v8")
    },
    useRuntimeMonitorVoltageLevel1v2(): number | undefined {
      return metrics.useNumberValue("runtime_monitor", "voltage_level_1v2")
    },
    useSatellitePositionLat(): number | undefined {
      return metrics.useNumberValue("satellite_position", "lat")
    },
    useSatellitePositionLon(): number | undefined {
      return metrics.useNumberValue("satellite_position", "lon")
    },
    useSignalStatusStrength(): number | undefined {
      return metrics.useNumberValue("signal_status", "strength")
    },
    useSignalStatusReceiving(): boolean | undefined {
      return metrics.useBoolValue("signal_status", "receiving")
    },
    useSoftwareUpdateStatus(): SoftwareUpdateStatus | undefined {
      return metrics.useEnumValue("software_update", "status")
    },
    useSoftwareUpdateType(): SoftwareUpdateType | undefined {
      return metrics.useEnumValue("software_update", "type")
    },
    useSystemTimeTimestamp(): dayjs.Dayjs | undefined {
      return metrics.useTimestampValue("system_time", "timestamp")
    },
    useTargetOrientationAzimuth(): number | undefined {
      return metrics.useNumberValue("target_orientation", "azimuth")
    },
    useTargetOrientationElevation(): number | undefined {
      return metrics.useNumberValue("target_orientation", "elevation")
    },
    useTargetOrientationPolarization(): number | undefined {
      return metrics.useNumberValue("target_orientation", "polarization")
    },
  }
}

// The above variables are overriden during the build step in order to support
// multiple environments, such as local development, k8s with mock backend and
// the real production environment.

const HTTP_SCHEME = "https"
const WS_SCHEME = "wss"
const API_PORT = ""
// Not really a part of "edge", but we keep this here for convenience
export const MODEM_PAGE = "/modem"

export const EDGE_HTTP_API = `${HTTP_SCHEME}://${window.location.hostname}${API_PORT}/api`
export const EDGE_WS_API = `${WS_SCHEME}://${window.location.hostname}${API_PORT}/api/ws`
export const EDGE_WS_METRICS = `${EDGE_WS_API}/metrics?follow=true`
export const EDGE_WS_CONFIG = `${EDGE_WS_API}/config`
export const EDGE_WS_CONFIG_UPDATE = `${EDGE_WS_API}/config?update=true`
export const EDGE_WS_EVENTS = `${EDGE_WS_API}/events`
export const EDGE_WS_SOFTWARE_UPDATE = `${EDGE_WS_API}/software_update`
export const EDGE_HTTP_METADATA = `${EDGE_HTTP_API}/metadata`
export const EDGE_HTTP_FILE_UPLOAD = `${EDGE_HTTP_API}/file_upload`
export const EDGE_HTTP_LOGIN = `${EDGE_HTTP_API}/login`
export const EDGE_HTTP_LOGOUT = `${EDGE_HTTP_API}/logout`
